import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import axios, { AxiosError } from "axios";
import { CircleX, TriangleAlert } from "lucide-react";
import styled from "styled-components";
import * as Ariakit from "@ariakit/react";
import Button from "../../components/button/button";
import {
  Select,
  SelectItem,
  SelectProvider,
} from "../../components/form/select";
import SubmitButton from "../../components/form/submit";
import Label from "../../components/label";
import { Loader } from "../../components/loader";
import ResetCss from "../../components/ResetCss";
import Title from "../../components/title";
import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";
import { Icons } from "../../constants/icons";

import { BaseCourse, Certificate, CorsoType } from "../../types";
import { BE_BASE_URL } from "../../utils/config";
import { useSelectStore } from "@ariakit/react";
import { axiosInstance } from "../../provider/authProvider";
import { inherits } from "util";

const CLickWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: white;
  margin-bottom: 2rem;
`;

const InputField = styled.div`
  font-family: "Avenir Next", sans-serif;
  font-weight: bold;
  color: ${Color.BLUE};
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;

  & > label {
    padding-left: 1rem;
    padding-bottom: 0.2rem;
  }

  & > input {
    padding: 0.5rem 1rem;
    border: 2px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    font-size: 1rem;
  }

  & > textarea {
    padding: 0.5rem 1rem;
    border: 2px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    font-size: 1rem;
    overflow-y: auto;
  }
`;

const SubTitle = styled.h2`
  color: var(--blue300);
  font-family: Avenir Next Bold, sans-serif;
  font-size: 30px;
  font-weight: bold;
  padding-left: 10px;
  text-align: center;
`;

const UploadBtnWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: end;
  margin: 1rem;
  gap: 1rem;
`;

const UploadedFilesWrapper = styled.div`
  margin: 2rem;
  background-color: white;
  padding: 1rem 2rem;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const DataWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  height: 60rem;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;

  overflow: auto;
  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    border-radius: 4rem;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: ${Color.GREY};
  }
  @media ${BreakpointsQuery.tablet} {
    width: 960px;
  }
`;

const BaseTable = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr 1fr 2.5fr 1fr;
  gap: 1rem;
  padding: 1rem 0.2rem;
  align-items: center;
`;

const TableHeader = styled(BaseTable)`
  font-size: 0.8rem;
  color: var(--blue300);
  font-family: Avenir Next, sans-serif;
  font-weight: bold;
  border-bottom: 0.2rem solid ${Color.LIGHT_BLUE};
`;

const ListWrapper = styled.div`
  overflow-x: visible;
  overflow-y: auto;
  height: 20rem;
  border-bottom: 0.2rem solid ${Color.LIGHT_BLUE};
`;

const TableRow = styled(BaseTable)<{ $state: number; $selected: boolean }>`
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  padding: 1rem 0.2rem;
  color: ${Color.LIGHT_BLUE};
  text-transform: uppercase;
  font-size: 1rem;

  background-color: ${({ $selected, $state }) => {
    return $state === -1 || $state === -2 || $state === -3
      ? "#FF9190"
      : $selected
      ? Color.LIGHT_GREY
      : "transparent";
  }};

  &:hover {
    background-color: ${({ $state }) => {
      return $state === -1 || $state === -2 || $state === -3
        ? "#FF8888"
        : Color.LIGHT_GREY;
    }};
  }
`;

const SectionWrapper = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 16px;
  height: 100%;
`;
const LeftSectionWrapper = styled(SectionWrapper)`
  width: 43%;
  min-height: 63rem;
  overflow-y: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const RightSectionWrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 55%;
  gap: 1rem;
  min-height: 63rem; // Modifica in base alle tue necessità
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const PdfPreviewWrapper = styled.div`
  flex-grow: 1;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const UploadedListWrapper = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const UploadedListElement = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
  border-radius: 8px;
  color: ${Color.LIGHT_BLUE};
  &:hover {
    background-color: ${Color.LIGHT_GREY};
  }
`;

const PreviousCertificatesPage: React.FC = () => {
  const [pdfPreview, setPdfPreview] = useState<string | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<
    { file: File; fileType: string }[]
  >([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const updateFileInputRef = useRef<HTMLInputElement | null>(null);
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedCertificate, setSelectedCertificate] =
    useState<Certificate | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedCertificateIds, setSelectedCertificateIds] = useState<
    number[]
  >([]);
  const [courses, setCourses] = useState<Map<number, CorsoType> | null>(null);
  const [selectedCourse, setSelectedCourse] = useState<CorsoType | null>(null);
  const [loading, setLoading] = useState({
    show: false,
    message: "",
  });
  const courseStore = useSelectStore({
    value: `${selectedCourse?.id || ""}`,
    setValue: (value) => {
      setSelectedCourse(courses?.get(+value) || null);
    },
  });

  const emptyFormData = useMemo(
    () => ({
      nome: "",
      cognome: "",
      cf: "",
      titolo: "",
      codiceCorso: "",
      dataValidita: "",
      enteFormatore: "",
      codiceAttestato: "",
      inizioCorso: "",
    }),
    []
  );

  const resetForm = () => {
    setFormData(emptyFormData); // Resetta lo stato dei dati del form
    if (updateFileInputRef.current) {
      updateFileInputRef.current.value = ""; // Resetta il campo file
    }
  };
  const [formData, setFormData] = useState(emptyFormData);

  const fetchCertificates = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${BE_BASE_URL}/certificates/imported`
      );
      setCertificates(response.data);
    } catch (e) {
      const error = e as AxiosError;
      // console.error("Errore durante il recupero dei documenti:", error);
      alert(error.message || "Errore durante il recupero dei documenti.");
    }
  }, []);

  const fetchCourses = useCallback(async () => {
    try {
      const response = await axiosInstance.get<
        Omit<BaseCourse, "variationId">[]
      >(`${BE_BASE_URL}/courses`);
      if (response.data) {
        const courses: Map<number, CorsoType> = new Map<number, CorsoType>();
        response.data.forEach((c: Omit<BaseCourse, "variationId">) =>
          courses.set(c.id, {
            id: c.id,
            code: c.code,
            name: c.name,
          })
        );
        setCourses(courses);
      }
    } catch (e) {
      const error = e as AxiosError;
      alert(error.message || "Errore durante il recupero dei documenti.");
    }
  }, []);

  useEffect(() => {
    fetchCertificates();
    fetchCourses();
  }, [fetchCertificates, fetchCourses]);

  const handleDownloadTemplate = async () => {
    try {
      const response = await axiosInstance({
        url: BE_BASE_URL + "/certificates/download", // Il link del tuo endpoint backend
        method: "GET",
        responseType: "blob", // Questo è necessario per ricevere il file binario
      });

      // Creare un link temporaneo per scaricare il file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Attestato-Template.xlsx"); // Nome del file da scaricare
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Errore durante il download del template:", error);
    }
  };

  const renderCourseSelectItems = useCallback(
    (courses: Map<number, CorsoType>) => {
      return Array.from(courses?.entries() ?? []).map(([key, value]) => (
        <SelectItem key={`corso_${key}`} value={`${key}`}>
          {`[${value.code}] ${value.name}`}
        </SelectItem>
      ));
    },
    []
  );

  useEffect(() => {
    const handleFileUpload = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        const files = Array.from(target.files);

        const newFiles = files.filter(
          (file) =>
            !uploadedFiles.some((uploaded) => uploaded.file.name === file.name)
        );

        const fileEntries = newFiles.map((file) => ({
          file,
          fileType: fileInputRef.current?.accept === ".pdf" ? "pdf" : "xlsx",
        }));

        setUploadedFiles((prevFiles) => [...prevFiles, ...fileEntries]);
      }
    };

    if (fileInputRef.current) {
      fileInputRef.current.addEventListener("change", handleFileUpload);
    }

    return () => {
      if (fileInputRef.current) {
        fileInputRef.current.removeEventListener("change", handleFileUpload);
      }
    };
  }, [uploadedFiles]);

  const handleTypeAndUpload = (type: string) => {
    // Imposta il tipo accettato sull'input file basato sul pulsante cliccato
    if (fileInputRef.current) {
      if (type === "pdf") {
        fileInputRef.current.accept = ".pdf";
      } else if (type === "xlsx") {
        fileInputRef.current.accept = ".xls,.xlsx";
      }
      fileInputRef.current.click();
    }
  };

  interface ErrorResponse {
    message: string;
  }

  const uploadFiles = async () => {
    if (!uploadedFiles.length) return;
    const uploadedCertificates: Certificate[] = [];

    for (let i = 0; i < uploadedFiles.length; i++) {
      const { file, fileType } = uploadedFiles[i];
      const formData = new FormData();
      formData.append("files", file);
      formData.append("fileType", fileType);

      setLoading({ show: true, message: "Elaborazione in corso..." });
      try {
        const response = await axiosInstance.post(
          `${BE_BASE_URL}/certificates/imported`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status >= 400) {
          alert(
            "Errore imprevisto, riprovare e se il problema persiste contattare l'assistenza."
          );
        }
        const data: Certificate[] = response.data;
        uploadedCertificates.push(...data);
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorData = axiosError.response.data as ErrorResponse;
          alert(errorData.message || "An error occurred during upload.");
        } else {
          // In caso di errore di rete o altro
          alert("Network error or unexpected error occurred.");
        }
      } finally {
        setLoading({ show: false, message: "" });
      }
    }

    setCertificates((prev) => [...prev, ...uploadedCertificates]);
    setUploadedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const updateCertificate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const idValue = formData.get("certificate-id");
    if (typeof idValue === "string") {
      const id = Number(idValue);

      const fileInput = event.currentTarget.querySelector<HTMLInputElement>(
        'input[type="file"][name="file"]'
      );
      const file =
        fileInput?.files && fileInput.files[0] ? fileInput.files[0] : null;

      const updatedData = {
        nome: formData.get("nome") as string,
        cognome: formData.get("cognome") as string,
        codiceFiscaleDiscente: formData.get("codiceFiscaleDiscente") as string,
        titoloCorso: selectedCourse?.name || "",
        codiceCorso: selectedCourse?.code || "",
        dataValidita: formData.get("dataValidita") as string,
        enteFormatore: formData.get("enteFormatore") as string,
        codiceAttestato: formData.get("codiceAttestato") as string,
        inizioCorso: formData.get("inizioCorso") as string,
        file, // Usa il file solo se non è null
      };

      const submitData = new FormData();
      submitData.append("nome", updatedData.nome);
      submitData.append("cognome", updatedData.cognome);
      submitData.append(
        "codiceFiscaleDiscente",
        updatedData.codiceFiscaleDiscente
      );
      submitData.append("titoloCorso", updatedData.titoloCorso);
      submitData.append("codiceCorso", updatedData.codiceCorso);
      submitData.append("dataValidita", updatedData.dataValidita);
      submitData.append("enteFormatore", updatedData.enteFormatore);
      submitData.append("codiceAttestato", updatedData.codiceAttestato);
      submitData.append("inizioCorso", updatedData.inizioCorso);

      if (file) {
        submitData.append("file", file);
      }

      let success = true;
      let errorMessage = "";
      try {
        // Effettua la richiesta per aggiornare il certificato
        await axiosInstance
          .put(`${BE_BASE_URL}/certificates/imported/${id}`, updatedData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function (error) {
            console.error(
              "Errore durante la validazione dell'attestato:",
              error
            );
            success = false;
            errorMessage =
              error.response.data.message ||
              "Errore imprevisto durante la validazione dell'attestato.";
          });
        // Refresh lista attestati con gli eventuali dati aggiornati
        await fetchCertificates();
        if (success) {
          // Se validazione ok reset del form
          setSelectedCertificate(null);
          resetForm();
          courseStore.setValue("");
          setPdfPreview(null);
          setSelectedCourse(null);
          alert("Attestato aggiornato con successo.");
        }
      } catch (e) {
        const error = e as AxiosError;
        console.error("Errore imprevisto validazione attestato:", error);
        success = false;
        errorMessage =
          error.message ||
          "Errore imprevisto durante la validazione dell'attestato.";
      } finally {
        if (!success) {
          alert(errorMessage);
        }
      }
    } else {
      // console.error("ID dell'attestato non trovato o non è valido.");
      alert("ID dell'attestato non valido.");
    }
  };

  const handleCancel = () => {
    resetForm();
    setSelectedCertificate(null);
    courseStore.setValue("");
    setErrorMessage(null);
    setPdfPreview(null);
  };

  const handleRowSelect = (cert: Certificate) => {
    setSelectedCertificate(cert);
    setPdfPreview(null);
    setFormData({
      nome: cert.nome || "",
      cognome: cert.cognome || "",
      cf: cert.codiceFiscaleDiscente || "",
      titolo: cert.titoloCorso || "",
      codiceCorso: cert.codiceCorso || "",
      dataValidita: cert.dataValidita || "",
      enteFormatore: cert.enteFormatore || "",
      codiceAttestato: cert.codiceAttestato || "",
      inizioCorso: cert.inizioCorso || "",
    });
    if (cert.stato === -1) {
      setErrorMessage(
        "Attenzione: il dipendente non ha un codice fiscale valido o non è registrato in anagrafica! Registrare prima il dipendente nella tabella anagrafica e poi procedere con la validazione."
      );
    } else if (cert.stato === -2) {
      setErrorMessage(
        "Attenzione: il codice corso del dipendente non è presente o non corrisponde ad alcun corso registrato! Selezionare un corso valido dal menu a tendina."
      );
    } else if (cert.stato === -3) {
      setErrorMessage(
        "Attenzione: il dipendente non è registrato in anagrafica ed è associato ad un corso non presente in registro! Registrare il dipendente e associare il corretto corso dal menu a tendina."
      );
    } else {
      setErrorMessage(null);
    }
    // cerco fra i courses quello con il codice corso uguale a cert.codiceCorso
    const course = Array.from(courses?.values() || []).find(
      (c) =>
        cert.codiceCorso &&
        c.code.toLowerCase() === cert.codiceCorso.toLowerCase()
    );
    // Se lo trovo imposto il corso selezionato
    if (course) {
      courseStore.setValue(`${course.id}`);
      setSelectedCourse(course);
    } else {
      courseStore.setValue("");
      setSelectedCourse(null);
    }
    setPdfPreview(
      cert.fileData ? `data:application/pdf;base64,${cert.fileData}` : null
    );
  };

  const formWrapperRef = useRef<HTMLFormElement | null>(null); // Riferimento per il modulo di aggiornamento

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const element = document.getElementById("click-inside-wrapper");

      if (element) {
        const isClickInside = element.contains(event.target as Node);
        if (!isClickInside) {
          // Resetta se il click è al di fuori di lista, form e del tasto cancella
          if (selectedCertificate) {
            setSelectedCertificate(null);
            resetForm();
            courseStore.setValue("");
            setErrorMessage(null);
            setPdfPreview(null);
          }
        }
      }
    },
    [selectedCertificate, resetForm, courseStore]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedCertificateIds([]); // Deseleziona tutti
    } else {
      const allIds = certificates
        .filter((cert) => cert.stato === 1)
        .map((cert) => cert.id); // Prendi tutti gli ID dai certificati validi
      setSelectedCertificateIds(allIds); // Seleziona tutti
    }
    setAllSelected(!allSelected); // Inverti lo stato di allSelected
  };

  const toggleCertificateSelection = (id: number) => {
    setSelectedCertificateIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const validateAll = async () => {
    if (selectedCertificateIds.length === 0) {
      alert("Seleziona almeno un certificato da validare.");
      return;
    }
    if (
      window.confirm(
        "Si è sicuri di continuare l'operazione e se ne assume la responsabilità?"
      )
    ) {
      try {
        // Trova i dati dei certificati selezionati
        const selectedCertificatesData = certificates.filter((certificate) =>
          selectedCertificateIds.includes(certificate.id)
        );

        await axiosInstance.put(
          `${BE_BASE_URL}/certificates/imported`,
          selectedCertificatesData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        alert("Attestati aggiornati correttamente!");
        fetchCertificates();
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const error = e as AxiosError;

          if (error.response) {
            // Accedi alla proprietà data come any per evitare errori di tipo
            const errorMessage = (error.response.data as any).message;
            alert(
              errorMessage || "Errore durante l'aggiornamento degli attestati."
            );
          } else {
            alert("Errore durante l'aggiornamento degli attestati.");
          }
        } else {
          alert("Errore sconosciuto durante l'aggiornamento degli attestati.");
        }
      }
    }
  };

  const deleteSelectedCertificates = async () => {
    if (selectedCertificateIds.length === 0) {
      alert("Seleziona almeno un certificato da eliminare.");
      return;
    }

    if (
      window.confirm("Sei sicuro di voler eliminare i certificati selezionati?")
    ) {
      try {
        await axiosInstance.delete(`${BE_BASE_URL}/certificates/imported`, {
          params: { ids: selectedCertificateIds.join(",") },
        });
        alert("Certificati eliminati con successo!");
        fetchCertificates(); // Richiedi la lista aggiornata dei certificati
        setSelectedCertificateIds([]); // Pulire le selezioni
        setAllSelected(false); // Resettare lo stato di selezione globale
      } catch (e) {
        const error = e as AxiosError;
        // console.error("Errore durante l'eliminazione dei certificati:", error);
        alert(
          error.message || "Si è verificato un errore durante l'eliminazione."
        );
      }
    }
  };

  const handleDeletePdf = async () => {
    if (selectedCertificate) {
      const id = selectedCertificate.id; // Ottieni l'ID del certificato
      try {
        await axiosInstance.put(`${BE_BASE_URL}/certificates/${id}/delete-pdf`);
        // Successo nel cancellare il PDF
        setPdfPreview(null); // Resetta l'anteprima PDF

        // Chiama setSelectedCertificate senza modificare gli altri campi
        setSelectedCertificate((prev) =>
          prev ? { ...prev, fileData: null } : null
        );

        await fetchCertificates();

        alert("PDF associato eliminato con successo.");
      } catch (error) {
        const axiosError = error as AxiosError;
        const errorMessage =
          (axiosError.response?.data as any).message ||
          "Errore durante l'eliminazione del PDF.";
        alert(errorMessage);
      }
    } else {
      alert("Nessun certificato selezionato.");
    }
  };

  const handleFileUpdate = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setPdfPreview(reader.result as string); // Imposta l'anteprima PDF
      };
      reader.readAsDataURL(file); // Leggi il file come Data URL
    }
  };

  // Effetto per aggiungere l'event listener al file input
  useEffect(() => {
    if (updateFileInputRef.current) {
      updateFileInputRef.current.addEventListener("change", handleFileUpdate);
    }

    return () => {
      if (updateFileInputRef.current) {
        updateFileInputRef.current.removeEventListener(
          "change",
          handleFileUpdate
        );
      }
    };
  }, []);
  /*
  const determineInputColor = (inputId: string, state?: number | null) => {
    const redStateIds = {
      "-1": ["codiceFiscaleDiscente"],
      "-2": ["codice_attestato"],
      "-3": ["codiceFiscaleDiscente", "codice_attestato"],
    };

    // Controlla se l'id del input è incluso nello stato corrente
    return redStateIds[state]?.includes(inputId) ? Color.RED : Color.LIGHT_BLUE;
  };

  const determineInput = (
    inputId: string,
    inputValue: string,
    state?: number
  ) => {
    const isControlled =
      inputId === "codiceFiscaleDiscente" || inputId === "codice_attestato";
    const borderColor = determineInputColor(inputId, state);

    if (isControlled) {
      return (
        <ControlledInput
          key={inputId}
          type="text"
          id={inputId}
          name={inputId}
          placeholder={`Inserisci ${inputId.replace("_", " ")}`}
          value={inputValue}
          onChange={(e) => onChangeHandler(inputId, e.target.value)}
          $state={state}
          style={{ borderColor }} // Applicare il colore del bordo
          required
        />
      );
    } else {
      return (
        <input
          key={inputId}
          type="text"
          id={inputId}
          name={inputId}
          placeholder={`Inserisci ${inputId.replace("_", " ")}`}
          value={inputValue}
          onChange={(e) => onChangeHandler(inputId, e.target.value)}
          required
        />
      );
    }
  };
*/
  const selectStyle = {
    "--padBlock": "0.5rem",
    border: "2px solid var(--blue300)",
    borderRadius: "1rem",
    fontSize: "1rem",
  } as React.CSSProperties;

  return (
    <ResetCss style={{ width: "60rem", margin: "0 auto" }}>
      <Loader show={loading.show}>{loading.message}</Loader>
      <PageWrapper>
        <Title text={"CARICA E GESTISCI ATTESTATI"} />
        <UploadBtnWrapper>
          <input
            id="file-upload"
            type="file"
            multiple
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <Button
            label="Carica PDF"
            color={Color.GREEN}
            icon={Icons.BUTTONS.UPLOAD}
            onClick={() => handleTypeAndUpload("pdf")}
          />
          {
            <Button
              label="Carica XSLX"
              color={Color.BLUE}
              icon={Icons.BUTTONS.UPLOAD}
              onClick={() => handleTypeAndUpload("xlsx")}
            />
          }
          <Button
            label="Scarica Template"
            color={Color.BLUE}
            icon={Icons.BUTTONS.DOWNLOAD}
            onClick={() => handleDownloadTemplate()}
          />
        </UploadBtnWrapper>

        <UploadedFilesWrapper id="uploaded-files-container">
          <Label text="File Caricati:"></Label>
          <UploadedListWrapper id="uploaded-files-list">
            {uploadedFiles.map(({ file }, index) => (
              <UploadedListElement key={`file_upload_${index}`}>
                <span
                  style={{
                    width: "50rem",
                    display: "inline-block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {file.name}
                </span>
                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "red",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    justifyContent: "center",
                    flexShrink: "0",
                  }}
                  onClick={() => {
                    const updatedFiles = uploadedFiles.filter(
                      (_, i) => i !== index
                    );
                    setUploadedFiles(updatedFiles);
                    if (fileInputRef.current) {
                      fileInputRef.current.value = "";
                    }
                  }}
                >
                  <CircleX width={18} height={18} />
                </button>
              </UploadedListElement>
            ))}
          </UploadedListWrapper>
        </UploadedFilesWrapper>

        <ButtonWrapper>
          <Button
            label="Elabora Attestati"
            color={Color.GREEN}
            icon={Icons.BUTTONS.EDIT}
            onClick={uploadFiles}
          />
        </ButtonWrapper>
        <CLickWrapper id="click-inside-wrapper">
          <TableWrapper id="certificates-list">
            <SubTitle>ATTESTATI DA VALIDARE</SubTitle>
            <TableHeader>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  type="checkbox"
                  defaultChecked={allSelected}
                  onChange={handleSelectAll}
                />
              </div>
              <p>NOME</p>
              <p>COGNOME</p>
              <p>TITOLO CORSO</p>
              <p>ENTE FORMATORE</p>
            </TableHeader>

            <ListWrapper>
              {certificates &&
                certificates.map((singleCertificate) => {
                  const isSelected = selectedCertificateIds.includes(
                    singleCertificate.id
                  );
                  return (
                    <TableRow
                      role={"row"}
                      tabIndex={0}
                      key={`attestato_${singleCertificate.id}`}
                      onClick={() => handleRowSelect(singleCertificate)}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleRowSelect(singleCertificate);
                        }
                      }}
                      $selected={
                        selectedCertificate?.id == singleCertificate.id
                      }
                      $state={singleCertificate.stato}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={isSelected}
                          onChange={() =>
                            toggleCertificateSelection(singleCertificate.id)
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                      <p>{singleCertificate.nome}</p>
                      <p>{singleCertificate.cognome}</p>
                      <p>{singleCertificate.titoloCorso}</p>
                      <p>{singleCertificate.enteFormatore}</p>
                    </TableRow>
                  );
                })}
            </ListWrapper>
          </TableWrapper>

          <ButtonWrapper>
            <Button
              label="Elimina Selezionati"
              color={Color.RED}
              onClick={deleteSelectedCertificates}
            />
            <Button
              label="Valida Selezionati"
              color={Color.BLUE}
              onClick={validateAll}
            />
          </ButtonWrapper>

          <DataWrapper>
            <LeftSectionWrapper>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SubTitle
                  style={{
                    fontSize: "20px",
                    marginRight: "0.5rem",
                    paddingBottom: "1rem",
                  }}
                >
                  Attestato
                </SubTitle>
                {errorMessage && (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      paddingBottom: "1rem",
                    }}
                  >
                    <Ariakit.HovercardProvider>
                      <Ariakit.HovercardAnchor render={<div />}>
                        <TriangleAlert
                          color={Color.RED}
                          height={45}
                          width={45}
                        />
                      </Ariakit.HovercardAnchor>
                      <Ariakit.Hovercard
                        fixed
                        gutter={10}
                        style={{
                          backgroundColor: "var(--red500)",
                          color: "var(--white)",
                          padding: "1em",
                          borderRadius: "8px",
                          width: "50vw",
                        }}
                      >
                        <p>{errorMessage}</p>
                      </Ariakit.Hovercard>
                    </Ariakit.HovercardProvider>
                  </span>
                )}
              </div>
              <FormWrapper
                id="update-certificate-form"
                ref={formWrapperRef}
                onSubmit={updateCertificate}
              >
                <input
                  type="hidden"
                  name="certificate-id"
                  defaultValue={selectedCertificate?.id}
                />
                <InputField>
                  <label htmlFor="nome">Nome:</label>
                  <input
                    type="text"
                    id="nome"
                    name="nome"
                    placeholder="Il nome del partecipante"
                    value={formData.nome}
                    onChange={(e) =>
                      setFormData({ ...formData, nome: e.target.value })
                    }
                    required
                  />
                </InputField>
                <InputField>
                  <label>Cognome:</label>
                  <input
                    type="text"
                    id="cognome"
                    name="cognome"
                    placeholder="Il cognome del partecipante"
                    value={formData.cognome}
                    onChange={(e) =>
                      setFormData({ ...formData, cognome: e.target.value })
                    }
                    required
                  />
                </InputField>
                <InputField>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      color:
                        selectedCertificate?.stato === -1 ||
                        selectedCertificate?.stato === -3
                          ? Color.RED
                          : "inherit",
                    }}
                  >
                    Codice fiscale:
                    {(selectedCertificate?.stato === -1 ||
                      selectedCertificate?.stato === -3) && (
                      <TriangleAlert color={Color.RED} height={16} width={16} />
                    )}
                  </label>
                  <input
                    type="text"
                    id="codiceFiscaleDiscente"
                    name="codiceFiscaleDiscente"
                    placeholder="Il CF del partecipante"
                    value={formData.cf}
                    onChange={(e) => {
                      const codiceFiscaleValue = e.target.value;
                      setFormData({ ...formData, cf: codiceFiscaleValue });
                    }}
                    style={{
                      borderColor:
                        selectedCertificate?.stato === -1 ||
                        selectedCertificate?.stato === -3
                          ? Color.RED
                          : Color.LIGHT_BLUE,
                    }}
                    required
                  />
                </InputField>

                <InputField title={selectedCourse?.name ?? ""}>
                  <label>Corso</label>
                  <SelectProvider store={courseStore}>
                    <Select
                      style={selectStyle}
                      sameWidth={false}
                      renderValue={(value) => {
                        return value
                          ? courses?.get(+value)?.name
                          : "Seleziona un corso...";
                      }}
                    >
                      {courses ? renderCourseSelectItems(courses) : null}
                    </Select>
                  </SelectProvider>
                </InputField>
                <InputField>
                  <label>Data scadenza:</label>
                  <input
                    type="date"
                    id="data_scadenza"
                    name="dataValidita"
                    placeholder="Data di scadenza"
                    value={formData.dataValidita}
                    onChange={(e) =>
                      setFormData({ ...formData, dataValidita: e.target.value })
                    }
                    required
                  />
                </InputField>
                <InputField>
                  <label>Data inizio corso:</label>
                  <input
                    type="date"
                    id="data_inizio_corso"
                    name="inizioCorso"
                    placeholder="Data di inizio del corso"
                    value={formData.inizioCorso}
                    onChange={(e) =>
                      setFormData({ ...formData, inizioCorso: e.target.value })
                    }
                    required
                  />
                </InputField>
                <InputField>
                  <label>Ente formatore:</label>
                  <input
                    type="text"
                    id="ente_formatore"
                    name="enteFormatore"
                    placeholder="L'ente che ha rilasciato l'attestato"
                    value={formData.enteFormatore}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        enteFormatore: e.target.value,
                      })
                    }
                    required
                  />
                </InputField>
                <InputField>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      color:
                        selectedCertificate?.stato === -2 ||
                        selectedCertificate?.stato === -3
                          ? Color.RED
                          : "inherit",
                    }}
                  >
                    Codice attestato:{" "}
                    {(selectedCertificate?.stato === -2 ||
                      selectedCertificate?.stato === -3) && (
                      <TriangleAlert color={Color.RED} height={16} width={16} />
                    )}
                  </label>
                  <input
                    type="text"
                    id="codice_attestato"
                    name="codiceAttestato"
                    placeholder="Il codice identificativo dell'attestato"
                    value={formData.codiceAttestato}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        codiceAttestato: e.target.value,
                      })
                    }
                    style={{
                      borderColor:
                        selectedCertificate?.stato === -2 ||
                        selectedCertificate?.stato === -3
                          ? Color.RED
                          : Color.LIGHT_BLUE,
                    }}
                    required
                  />
                </InputField>
                <InputField>
                  <label>Carica attestato:</label>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    accept=".pdf"
                    ref={updateFileInputRef}
                  />
                </InputField>
                <ButtonWrapper>
                  <SubmitButton
                    label="VALIDA E CARICA"
                    form="update-certificate-form"
                    color={Color.BLUE}
                    name="SubmitButton"
                    style={{ padding: "0.3rem 1rem" }}
                  />
                  <Button
                    label="ANNULLA"
                    color={Color.RED}
                    onClick={handleCancel}
                  />
                </ButtonWrapper>
              </FormWrapper>
            </LeftSectionWrapper>
            <RightSectionWrapper>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SubTitle style={{ fontSize: "20px" }}>Anteprima PDF</SubTitle>
                {selectedCertificate && selectedCertificate.fileData && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeletePdf();
                    }}
                    label="CANCELLA"
                    color={Color.RED}
                  />
                )}
              </div>
              <PdfPreviewWrapper id="pdf-preview">
                {pdfPreview ? (
                  <embed
                    src={pdfPreview}
                    width="100%"
                    height="100%"
                    type="application/pdf"
                  />
                ) : selectedCertificate?.fileData ? (
                  <embed
                    src={`data:application/pdf;base64,${selectedCertificate.fileData}`}
                    width="100%"
                    height="100%"
                    type="application/pdf"
                  />
                ) : (
                  <p style={{ textAlign: "center", color: Color.BLACK }}>
                    Nessun PDF associato a questo attestato
                  </p>
                )}
              </PdfPreviewWrapper>
            </RightSectionWrapper>
          </DataWrapper>
        </CLickWrapper>
      </PageWrapper>
    </ResetCss>
  );
};

export default PreviousCertificatesPage;
