import React from "react";
import styled from "styled-components";
import { Color } from "../../../../constants/color";
import { Icons } from "../../../../constants/icons";
import Label from "../../../label";
import Button from "../../../button/button";
import { CourseCarouselItem } from "../../../../types";
import {
  getCourseCover,
  getCourseMode,
  goToPage,
} from "../../../../utils/utils";
import { Building2, MapPin, Users } from "lucide-react";
import { BreakpointsQuery } from "../../../../constants/device";
import { useTranslation } from "react-i18next";

interface Props {
  item: CourseCarouselItem;
}

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${Color.WHITE};
  box-shadow: 0 0 20px 10px ${Color.SHADOW};
  font-weight: bolder;
  border-radius: 20px;
  max-height: 9rem;
  max-width: 62rem;
  height: 26rem;
`;

const CourseImage = styled.div`
  flex-grow: 1;
  min-height: 7.4rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
`;

const Name = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 2rem;
  background-color: ${Color.LIGHT_BLUE};
  border-top-right-radius: 20px;

  div {
    color: ${Color.WHITE};
    text-align: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  max-width: 50rem;
  @media ${BreakpointsQuery.tablet} {
    max-width: 43rem;
  }
`;

const Info = styled.div`
  display: flex;
  row-gap: 0.6rem;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
  button {
    margin-top: 1.2rem;
    margin-right: 14px;
    margin-left: 14px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  max-width: 50rem;
  padding-left: 2rem;
  column-gap: 10rem;
  height: 112px;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  width: 40rem;
  align-items: center;
  div {
    text-align: left;
    //max-width: 8rem;
  }
`;

const SubInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1.4rem;
  img {
    flex-grow: 1;
  }
  div {
    flex-grow: 5;
    text-align: left;
    max-width: 180px;
  }
`;

const ListItem = ({ item }: Props) => {
  const { t } = useTranslation();
  return (
    <StyledItem>
      <CourseImage>
        <img alt={"Immagine corso"} src={getCourseCover(item.type)} />
      </CourseImage>
      <Column>
        <Name>
          <Label
            text={item.courseName}
            textTransform={"uppercase"}
            fontWeight={"bold"}
            fontSize={"18px"}
            textAlign={"center"}
            color={Color.LIGHT_BLUE}
          />
        </Name>
        <InfoRow>
          <Info>
            <InfoColumn>
              <SubInfoColumn>
                <Users color={Color.LIGHT_BLUE} />
              </SubInfoColumn>
              <SubInfoColumn>
                <Label
                  text={t("book-course-lecture-mode")}
                  textTransform={"uppercase"}
                  fontWeight={"bold"}
                  fontSize={"12px"}
                  textAlign={"center"}
                  color={Color.DARK_GREY}
                />
                <Label
                  text={t(getCourseMode(item.mode))}
                  textTransform={"uppercase"}
                  fontWeight={"bold"}
                  fontSize={"15px"}
                  textAlign={"center"}
                  color={Color.LIGHT_BLUE}
                />
              </SubInfoColumn>
            </InfoColumn>
            <InfoColumn>
              <SubInfoColumn>
                <MapPin color={Color.LIGHT_BLUE} />
              </SubInfoColumn>
              <SubInfoColumn>
                <Label
                  text={t("book-course-city")}
                  textTransform={"uppercase"}
                  fontWeight={"bold"}
                  fontSize={"12px"}
                  textAlign={"center"}
                  color={Color.DARK_GREY}
                />
                <Label
                  text={item.city}
                  textTransform={"uppercase"}
                  fontWeight={"bold"}
                  fontSize={"15px"}
                  textAlign={"center"}
                  color={Color.LIGHT_BLUE}
                />
              </SubInfoColumn>
            </InfoColumn>
            <InfoColumn>
              <SubInfoColumn>
                {item.address.length !== 0 && (
                  <Building2 color={Color.LIGHT_BLUE} />
                )}
              </SubInfoColumn>
              {item.address.length !== 0 && (
                <SubInfoColumn>
                  <Label
                    text={t("book-course-headquarter")}
                    textTransform={"uppercase"}
                    fontWeight={"bold"}
                    fontSize={"12px"}
                    textAlign={"center"}
                    color={Color.DARK_GREY}
                  />
                  <Label
                    text={item.address}
                    textTransform={"capitalize"}
                    fontWeight={"bold"}
                    fontSize={"15px"}
                    textAlign={"center"}
                    color={Color.LIGHT_BLUE}
                  />
                </SubInfoColumn>
              )}
            </InfoColumn>
            <Button
              label={t("book-course-reserve-button")}
              color={Color.BLUE}
              icon={Icons.COMMONS.CALENDAR}
              onClick={() => goToPage("corso", item.courseId)}
            />
          </Info>
        </InfoRow>
      </Column>
    </StyledItem>
  );
};

export default ListItem;
