import styled from "styled-components";
import { Color } from "../../../constants/color"; // Assicurati di avere il corretto percorso per importare Color

export const ModalLabel = styled.label`
  font-family: "Avenir Next", sans-serif;
  font-weight: bold;
  color: ${Color.LIGHT_BLUE};
`;

export const ModalWrapper = styled.div<{ $isVisible: boolean }>`
  display: ${(props) => (props.$isVisible ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: ${(props) => (props.$isVisible ? 999 : -1)};
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  overflow-y: auto;
  max-height: 90vh;
`;
