import { useState } from "react";

import styled from "styled-components";

import { Color } from "../../constants/color";
import { Select, SelectItem, SelectProvider } from "../form/select";
import { useSelectStore } from "@ariakit/react";
import { Check, X } from "lucide-react";

type BookableEmployeeType = {
  cf: string;
  name: string;
};

type SelectedBookableEmployeeType = BookableEmployeeType & { index: number };

const OutlineButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${Color.WHITE};
  background-color: ${Color.BLUE};
  border: 0;
  padding: 0.3em 1.5em;
  border-radius: 8px;
  font-size: 1.6em;
  font-weight: bold;
  cursor: pointer;
`;

function BookableEmployees({
  employees,
  selectedEmployee,
  onEmployeeBooked,
  undoEmployeeBooked,
}: {
  employees: BookableEmployeeType[];
  selectedEmployee?: SelectedBookableEmployeeType | undefined;
  onEmployeeBooked?: (cf: string) => void;
  undoEmployeeBooked?: (selected: SelectedBookableEmployeeType) => void;
}) {
  const [selectedValue, setSelectedValue] = useState<string>(
    selectedEmployee?.cf || ""
  );
  const selectEmployee = useSelectStore({
    defaultValue: selectedValue,
    setValue: (value: string) => {
      setSelectedValue(value);
    },
  });

  function handleEmployeeBooking() {
    // console.log("Employee selected", selectedEmployeeRef.current);
    if (selectedValue && onEmployeeBooked) {
      onEmployeeBooked(selectedValue);
    }
  }

  function handleEmployeeUnbooking() {
    console.log("Employee selected to remove", {
      selectedEmployee,
      selectedValue,
    });
    if (selectedValue && undoEmployeeBooked) {
      setSelectedValue("");
      selectEmployee.setValue("");
      undoEmployeeBooked(selectedEmployee as SelectedBookableEmployeeType);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        gap: ".8rem",
      }}
    >
      {selectedEmployee ? (
        <>
          <div
            style={{
              minWidth: "250px",
            }}
          >
            {selectedEmployee.name}
          </div>
          <OutlineButton
            type="button"
            onClick={handleEmployeeUnbooking}
            style={{
              backgroundColor: Color.RED,
              padding: ".3rem .5rem",
            }}
          >
            <X color={Color.WHITE} />
          </OutlineButton>
        </>
      ) : (
        <>
          <SelectProvider store={selectEmployee}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.1rem",
              }}
            >
              <Select
                style={{
                  minWidth: "250px",
                }}
                renderValue={(value) => {
                  if (value === "") return "Seleziona un discente";
                  return employees.find((e) => e.cf === value)?.name || "";
                }}
                modal={true}
              >
                <SelectItem value="" disabled>
                  Selezionare un discente...
                </SelectItem>
                {employees.map((e, index) => (
                  <SelectItem
                    key={`bookableEmployee_${index}_${e.cf}`}
                    value={e.cf}
                  >
                    {e.name}
                  </SelectItem>
                ))}
              </Select>
            </div>
          </SelectProvider>
          {selectedValue !== "" && (
            <OutlineButton
              type="button"
              onClick={handleEmployeeBooking}
              style={{
                backgroundColor: Color.GREEN,
                padding: ".3rem .5rem",
              }}
            >
              <Check color={Color.WHITE} />
            </OutlineButton>
          )}
        </>
      )}
    </div>
  );
}

export {
  type BookableEmployeeType,
  type SelectedBookableEmployeeType,
  BookableEmployees,
};
