import React, {ChangeEvent} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Label from "../../label";
import Textbox from "../../form/input";
import {Color} from "../../../constants/color";
import {BreakpointsQuery} from "../../../constants/device";
import {UserPageResponse} from "../../../types";
import {Select, SelectItem, SelectLabel, SelectProvider} from "../../form/select";
import * as Ariakit from "@ariakit/react";

interface Props {
  show: boolean;
  edit: boolean;
  companyData: UserPageResponse | undefined;
  editCompanyData: (field: string, event?: ChangeEvent<HTMLInputElement>, salesValue?: string) => void;
}

const MainContainer = styled.div <{
    $show: boolean;
}>`
    display: ${props => props.$show ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    margin-top: 20px
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 80px;
    margin-top: 30px;
    input {
        background-color: ${Color.WHITE};
        box-shadow: none;
        border-style: solid;
        border-width: 2px;
        border-color: ${Color.LIGHT_BLUE};
        border-radius: 20px;
        width: 480px;
        height: 30px;
        @media ${BreakpointsQuery.tablet} {
            width: 400px;
        }
    }
    input:focus {
        outline-width: 0;
    }
    @media ${BreakpointsQuery.tablet} {
        column-gap: 20px;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 30px;
`;

const salesRange: { [key: string]: string } = {
  1: "<2 M/N Euro",
  2: "<10 M/N Euro",
  3: "<50 M/N Euro",
  4: ">50 M/N Euro",
};

const SecondInfo = ({show, edit, companyData, editCompanyData} : Props) => {
  const { t } = useTranslation();

  const selectSalesRange = Ariakit.useSelectStore({
    value: companyData ? companyData.companyInfo.salesRange.toString() : '*',
    setValue: (value) => {
      editCompanyData('companyInfo.salesRange', undefined, value)
    },
  });


  return (
      <MainContainer $show={show}>
        <Label text={t('user-second-info-label')}
               fontSize={'26px'}
               fontWeight={'bold'}
               textTransform={'uppercase'}
        />
        <InfoContainer>
          <Column>
            <Textbox label={t('user-eai-enrollment')}
                     alt={t('user-eai-enrollment')}
                     autocomplete={'off'}
                     form={'company-info'}
                     name={t('user-eai-enrollment')}
                     defaultValue={ companyData ? companyData.companyInfo.eaiEnrollment : ''}
                     type={'text'}
                     textAlign={'left'}
                     onChange={(e) => {
                       editCompanyData('companyInfo.eaiEnrollment', e)
                     }}
                     required={false}
                     edit={edit}
            />
            <Textbox label={t('user-legal-representative')}
                     alt={t('user-legal-representative')}
                     autocomplete={'off'}
                     form={'company-info'}
                     name={t('user-legal-representative')}
                     defaultValue={ companyData ? companyData.companyInfo.legalRepresentative : ''}
                     type={'text'}
                     textAlign={'left'}
                     onChange={(e) => {
                       editCompanyData('companyInfo.legalRepresentative', e)
                     }}
                     required={false}
                     edit={edit}
            />
            <Textbox label={t('user-first-level-sector')}
                     alt={t('user-first-level-sector')}
                     autocomplete={'off'}
                     form={'company-info'}
                     name={t('user-first-level-sector')}
                     defaultValue={ companyData ? companyData.companyInfo.level1Sector : ''}
                     type={'text'}
                     textAlign={'left'}
                     onChange={(e) => {
                       editCompanyData('companyInfo.level1Sector', e)
                     }}
                     required={false}
                     edit={edit}
            />
            <SelectProvider store={selectSalesRange}>
              <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
              >
                <SelectLabel
                    style={{
                      paddingLeft: "0.5rem",
                      fontSize: "14px",

                    }}
                >
                  {t('user-sales-range')}
                </SelectLabel>
                <Select
                    disabled={!edit}
                    style={{
                      minWidth: "200px",
                      borderStyle: "solid",
                      borderWidth: "2px",
                      borderRadius: "20px",
                      borderColor: Color.LIGHT_BLUE,
                      padding: "0",
                      paddingLeft: "190px",
                      height: "36px",
                    }}
                    renderValue={(value) => value !== '*' ? salesRange[`${value}`] : ''}
                >
                  {Object.entries(salesRange).map(([key, value]) => (
                      <SelectItem
                          key={`salesRange${key}`}
                          value={key}
                          style={{
                            justifyContent: "center"
                          }}
                          >
                        {value}
                      </SelectItem>
                  ))}
                </Select>
              </div>
            </SelectProvider>
            <Textbox label={t('user-number-of-ti-employees')}
                     alt={t('user-number-of-ti-employees')}
                     autocomplete={'off'}
                     form={'company-info'}
                     name={t('user-number-of-ti-employees')}
                     placeholder={companyData ? companyData.tiEmployees.toString() : ''}
                     type={'text'}
                     textAlign={'left'}
                     onChange={() => {}}
                     required={false}
                     edit={false}
            />
          </Column>
          <Column>
            <Textbox label={t('user-primary-cea-code')}
                     alt={t('user-primary-cea-code')}
                     autocomplete={'off'}
                     form={'company-info'}
                     name={t('user-primary-cea-code')}
                     defaultValue={ companyData ? companyData.companyInfo.primaryCeaCode : ''}
                     type={'text'}
                     textAlign={'left'}
                     onChange={(e) => {
                       editCompanyData('companyInfo.primaryCeaCode ', e)
                     }}
                     required={false}
                     edit={edit}
            />
            <Textbox label={t('user-second-level-sector-table')}
                     alt={t('user-second-level-sector-table')}
                     autocomplete={'off'}
                     form={'company-info'}
                     name={t('user-second-level-sector-table')}
                     defaultValue={ companyData ? companyData.companyInfo.level2SectorTable : ''}
                     type={'text'}
                     textAlign={'left'}
                     onChange={(e) => {
                       editCompanyData('companyInfo.level2SectorTable ', e)
                     }}
                     required={false}
                     edit={edit}
            />
            <Textbox label={t('user-inail-number')}
                     alt={t('user-inail-number')}
                     autocomplete={'off'}
                     form={'company-info'}
                     name={t('user-inail-number')}
                     defaultValue={ companyData ? companyData.companyInfo.inailNumber : ''}
                     type={'text'}
                     textAlign={'left'}
                     onChange={(e) => {
                       editCompanyData('companyInfo.inailNumber ', e)
                     }}
                     required={false}
                     edit={edit}
            />
            <Textbox label={t('user-number-of-employees')}
                     alt={t('user-number-of-employees')}
                     autocomplete={'off'}
                     form={'company-info'}
                     name={t('user-number-of-employees')}
                     placeholder={companyData ? companyData.totEmployees.toString() : ''}
                     type={'text'}
                     textAlign={'left'}
                     onChange={() => {}}
                     required={false}
                     edit={false}
            />
            <Textbox label={t('user-number-of-td-employees')}
                     alt={t('user-number-of-td-employees')}
                     autocomplete={'off'}
                     form={'company-info'}
                     name={t('user-number-of-td-employees')}
                     placeholder={companyData ? companyData.tdEmployees.toString() : ''}
                     type={'text'}
                     textAlign={'left'}
                     onChange={() => {}}
                     required={false}
                     edit={false}
            />
          </Column>
        </InfoContainer>
      </MainContainer>
  );
}

export default SecondInfo
