import React from "react";
import {dateOptionsNumeric, dateOptionsRange, getLocale} from "../../../utils/utils";
import styled from "styled-components";
import {Color} from "../../../constants/color";
import DatePickerProvider, {DaySlots, Header, Title as DateTitle, WeekDays, TCalendarConfig} from "headless-react-datepicker";
import Label from "../../label";
import {useTranslation} from "react-i18next";
import {Icons} from "../../../constants/icons";
import {useState} from "react";

interface Props {
  isRange: boolean;
  setDateFilters(date : Date[] | Date): void
}

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 266px;
    height: 54px;
`;

const Row = styled.div`
    display: flex;
    height: 30px;
    margin-top: 2px;
`;

const Search = styled.input`
    border-color: ${Color.LIGHT_BLUE};
    border-style: solid;
    flex-grow: 8;
    text-align: center;
    font-family: "Avenir Next Regular", sans-serif;
    font-size: 14px;
    text-transform: capitalize;
    outline: none;
`;

const ResetCalendarButton = styled.button`
    flex-grow: 1;
    background-color: ${Color.LIGHT_BLUE};
    color: ${Color.WHITE};
    font-size: 16px;
    border: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    text-transform: uppercase;
    
`;

const OpenCalendarButton = styled.button`
    flex-grow: 1;
    background-color: ${Color.LIGHT_BLUE};
    color: ${Color.WHITE};
    font-size: 16px;
    border: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    text-transform: uppercase;

`;

const DateSelectContainer = styled.div`
    font-family: Avenir Next, sans-serif;
`
const DateSelect = styled.div <{
    $show: boolean
}>`
    display: ${props => props.$show ? 'unset' : 'none'};
    position: absolute;
    top: 410px;
    background-color: ${Color.WHITE};
    border-radius: 10px;
    border-color: ${Color.GREY};
    border-style: solid;
    border-width: 1px;
    width: fit-content;
    padding: 10px 14px;

    svg {
        color: ${Color.GREEN};
        stroke-width: 2px;
    }
`

const Calendar = ({setDateFilters, isRange} : Props) => {
  const {t} = useTranslation()
  const [show, setShow] = useState(false)
  const [rangeValue, setRangeValue] = useState<Date[]>([new Date()])
  const [dateValue, setDateValue] = useState<Date>(new Date())

  const baseCalendarConfig: TCalendarConfig = {
    locale: getLocale(),
    weekStartsOn: 'monday',
    weekends: ["saturday", "sunday"],
    weekendSelectable: true
  }

  const rangeCalendarConfig: TCalendarConfig = {
    ...baseCalendarConfig,
    allowBackwardRange: true,
    yearRangeFrom: new Date().getFullYear(),
    yearRangeTo: new Date().getFullYear() + 2,
  }

  const singleCalendarConfig: TCalendarConfig = {
    ...baseCalendarConfig,
    allowBackwardRange: false,
    yearRangeFrom: new Date().getFullYear() - 10,
    yearRangeTo: new Date().getFullYear() + 10,
  }

  function printDate() : string {
    if (isRange) {
      const firstDate = rangeValue[0] ? rangeValue[0].toLocaleDateString(getLocale(), dateOptionsRange) : ''
      const secondDate = rangeValue[1] ? rangeValue[1].toLocaleDateString(getLocale(), dateOptionsRange) : ''
      if (secondDate !== '') return firstDate + ' - ' + secondDate;
      if (rangeValue.length > 0 && rangeValue[0].toLocaleDateString(getLocale(), dateOptionsRange) === new Date().toLocaleDateString(getLocale(), dateOptionsRange)) return ''
      return firstDate
    } else {
      return dateValue.toLocaleDateString(getLocale(), dateOptionsNumeric)
    }
  }

  return (
      <DateSelectContainer
          className={'dateFilter'}
          tabIndex={-1}
          onBlur={ (e) => {
            if (e.relatedTarget === null && !e.currentTarget.contains(e.relatedTarget)) setShow(false)
          }}
      >
        <SearchWrapper>
          <Label
              text={t('date-filter-label')}
              color={Color.WHITE}
              textTransform={'uppercase'}
              paddingLeft={10}
              paddingBottom={0}
              textAlign={'unset'}
              fontWeight={'bold'}
              fontSize={'16px'}
          />
          <Row>
            { isRange &&
              <ResetCalendarButton
                onClick={() => {
                  setDateFilters([])
                  setRangeValue([new Date()])
                }}
              >
                <img
                  src={Icons.BUTTONS.RESET}
                  alt={'reset search date'}
                  width={20}
                  style={{
                    position: 'relative',
                    left: '2px',
                    marginTop: '2px'
                  }}
                />
              </ResetCalendarButton>
            }
            <Search
                name={t('date-filter-label')}
                placeholder={''}
                readOnly={true}
                alt={t('date-filter-label')}
                form={t('date-filter-label')}
                type={'text'}
                value={printDate()}
                onChange={() => {
                  setDateFilters([new Date()])
                }}
            />
            <OpenCalendarButton
                onClick={ () => {
                  setShow(!show)
                }}
            >
              <img
                  src={Icons.COMMONS.CALENDAR}
                  alt={'open calendar search'}
                  width={20}
                  style={{
                    position: 'relative',
                    right: '2px',
                    marginTop: '2px'
                  }}
              />
            </OpenCalendarButton>
          </Row>

        </SearchWrapper>
        <DateSelect
            $show={show}
        >
          <DatePickerProvider
              config={isRange ? rangeCalendarConfig : singleCalendarConfig}
              defaultStartDate={new Date()}
              isRange={isRange}
              value={isRange ? rangeValue : dateValue}
              onChange={(newRange: Date[] | Date) => {
                if (Array.isArray(newRange)) {
                  setRangeValue(newRange)
                } else {
                  setDateValue(newRange)
                }
                setDateFilters(newRange)
              }}
          >
            <DateTitle
                style={{
                  textTransform: 'uppercase',
                  color: Color.BLUE,
                  textAlign: 'center',
                  fontSize: '18px',
                  fontFamily: "Avenir Next Regular, sans-serif",
                }}
                monthFormat={'long'}
            />
            <Header
                monthSelectStyles={{
                  fontSize: '16px',
                  fontFamily: "Avenir Next Regular, sans-serif",
                  textTransform: 'capitalize',
                  borderRadius: '4px',
                  borderColor: Color.GREY,
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  backgroundColor: Color.WHITE,
                  height: '22px',
                  position: 'relative',
                  bottom: '2px'
                }}
                yearSelectStyles={{
                  fontSize: '16px',
                  fontFamily: "Avenir Next Regular, sans-serif",
                  borderRadius: '4px',
                  borderColor: Color.GREY,
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  backgroundColor: Color.WHITE,
                  height: '22px',
                  position: 'relative',
                  bottom: '2px'
                }}
            />
            <WeekDays
                style={{
                  color: Color.BLUE,
                  fontFamily: "Avenir Next Regular, sans-serif",
                }}
            />
            <DaySlots
                todayStyles={{
                  color: Color.WHITE
                }}
                selectedStyles={{
                  backgroundColor: Color.LIGHT_BLUE,
                  borderRadius: '4px'
                }}
                slotStyles={{
                  fontFamily: "Avenir Next Regular, sans-serif",
                }}
                weekendStyles={{
                  color: Color.GREY
                }}
                todayParentStyles={{
                  backgroundColor: Color.GREEN,
                  borderRadius: '4px'
                }}
            />
          </DatePickerProvider>
        </DateSelect>
      </DateSelectContainer>
  );
}

export default Calendar
