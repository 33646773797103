import React, { ChangeEvent, useState, useRef, useEffect } from "react";
import { BE_BASE_URL } from "../../utils/config";
import { axiosInstance } from "../../provider/authProvider";
import { AxiosError } from "axios";
import styled from "styled-components";
import Label from "../../components/label";
import Button from "../../components/button/button";
import { Icons } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import { CircleX } from "lucide-react";
import { Color } from "../../constants/color";
import Title from "../../components/title";
import * as Ariakit from "@ariakit/react";
import { CompanyTrainingNeed } from "../../types";
import { Loader } from "../../components/loader";
import { TrainingNeedsLog } from "../../types";
import { format } from "date-fns";
import { url } from "inspector";

const RowLayout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.6em;
  padding: 0.5em 1em;
  text-align: center;
  align-items: center;
`;

const THead = styled(RowLayout)`
  font-weight: bold;
  text-transform: uppercase;
  color: var(--blue300);
`;

const TRow = styled(RowLayout)`
  color: var(--blue500);
  font-weight: bold;
  text-transform: uppercase;
  text-wrap: balance;
  border: 1px solid var(--grey800);
  border-radius: 8px;
  background-color: var(--grey100);
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  padding: 0.2em 1.2em;
`;

const UploadContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  width: 50%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 3rem;
`;

const FabbisognoContainer = styled.form`
  margin: 2rem 5rem 2rem 5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

interface NameContainerProps extends React.HTMLAttributes<HTMLSpanElement> {
  alt?: string;
}

const NameWrapper: React.FC<NameContainerProps> = ({
  alt,
  children,
  ...props
}) => (
  <NameContainer title={alt} {...props}>
    {children}
  </NameContainer>
);

const NameContainer = styled.span<NameContainerProps>`
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${Color.LIGHT_BLUE};
  font-weight: bold;
  font-family: inherit;
  font-size: 0.85rem;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: auto;
`;

const NameWrapperContainer = styled.div`
  display: flex;
  flex-direction: row; // Mantiene label e name together in un single row
  align-items: center; // Allinea verticalmente gli elementi
  flex-grow: 1; // Permette di adattarsi in base al contenuto
  min-width: 0; // Importante per evitare overflow del text
`;

const InputField = styled.div`
  font-family: "Avenir Next", sans-serif;
  font-weight: bold;
  color: ${Color.BLUE};
  width: 100%;
  & > label {
    padding-left: 1rem;
    padding-bottom: 0.2rem;
  }

  & > input {
    padding: 0.5rem 1rem;
    border: 2px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    font-size: 1rem;
  }

  & > textarea {
    padding: 0.5rem 1rem;
    border: 2px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    font-size: 1rem;
    overflow-y: auto;
  }
`;

interface LoadingState {
  show: boolean;
  message: string;
}

export async function fetchMappedFabbisognoData(
  setLoading: (state: LoadingState) => void
) {
  setLoading({ show: true, message: "Caricamento in corso..." });
  try {
    const response = await axiosInstance.get(
      `${BE_BASE_URL}/company-training-needs`
    );
    if (response.status === 200) {
      const trainingData: CompanyTrainingNeed[] = response.data;
      return {
        inclusi: trainingData.map((item) => ({
          id: item.id,
          title: item.courseTitle,
          fabbisogno: item.trainingNeed,
          attivi: item.active,
          dafare: item.pending,
        })),
        esclusi: [],
      };
    }
  } catch (error) {
    console.error("Errore durante il recupero e la mappatura dei documenti.");
  } finally {
    setLoading({ show: false, message: "" });
  }

  return { inclusi: [], esclusi: [] };
}

const TrainingNeedsPage: React.FC = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fabbisognoData, setFabbisognoData] = useState<CompanyTrainingNeed[]>(
    []
  );
  const [editTrainingNeed, setEditTrainingNeed] = useState<{
    [key: number]: number;
  }>({});
  const [isTableEdited, setIsTableEdited] = useState(false);
  const [loading, setLoading] = useState({
    show: false,
    message: "",
  });
  const [trainingNeedsLog, setTrainingNeedsLog] = useState<TrainingNeedsLog>();

  useEffect(() => {
    fetchTrainingNeedsLog();
    fetchTrainingNeeds();
  }, [isTableEdited, isFileUploaded]);

  const fetchTrainingNeeds = async () => {
    setLoading({ show: true, message: "Caricamento in corso..." });
    try {
      const response = await axiosInstance.get(
        `${BE_BASE_URL}/company-training-needs`
      );
      if (response.status === 200) {
        const data: CompanyTrainingNeed[] = response.data;
        setFabbisognoData(data);
        const initialValues = data.reduce(
          (acc, row) => ({ ...acc, [row.id]: row.trainingNeed }),
          {}
        );
        setEditTrainingNeed(initialValues);
        setIsTableEdited(false);
      }
    } catch (e) {
      const error = e as AxiosError;
      alert(error.message || "Errore durante il recupero dei documenti.");
    } finally {
      setLoading({ show: false, message: "" });
    }
  };

  const fetchTrainingNeedsLog = async () => {
    try {
      const response = await axiosInstance.get(
        `${BE_BASE_URL}/training-need-logs`
      );
      if (response.data) {
        setTrainingNeedsLog(response.data);
      }
    } catch (e) {
      const error = e as AxiosError;
      alert(error.message || "Errore durante il recupero dei documenti.");
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target.files) {
      const uploadedFile = target.files[0];
      setFile(uploadedFile);
    }
  };

  const handleClickRef = () => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = ".xlsx, xls";
      fileInputRef.current.click();
    }
  };

  interface ErrorResponse {
    message?: string;
  }

  const handleFileProcess = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await axiosInstance.post(
          `${BE_BASE_URL}/company-training-needs`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert("Documento caricato con successo");
        setIsFileUploaded(true);
        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        let errorMessage = "Errore durante il salvataggio del file.";
        if (axiosError.response) {
          const data = axiosError.response.data as ErrorResponse;
          if (data && data.message) {
            errorMessage = data.message;
          }
        } else if (axiosError.message) {
          errorMessage = axiosError.message;
        }
        alert(errorMessage);
      }
    } else {
      alert("Nessun file selezionato.");
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      const response = await axiosInstance({
        url: BE_BASE_URL + "/company-training-needs/download",
        method: "GET",
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Fabbisogno-Template.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Errore durante il download del template:", error);
    }
  };

  const handleBatchSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const payload = fabbisognoData.map((row) => ({
      id: row.id,
      trainingNeed: parseInt(
        formData.get(`trainingNeed-${row.id}`) as string,
        10
      ),
    }));
    try {
      const response = await axiosInstance.put(
        `${BE_BASE_URL}/company-training-needs`,
        payload
      );
      if (response.status === 200) {
        alert("Modifiche salvate con successo");
        await axiosInstance.post(
          `${BE_BASE_URL}/training-need-logs`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setIsTableEdited(true);
      }
    } catch (e) {
      const error = e as AxiosError;
      alert(error.message || "Errore durante il salvataggio delle modifiche.");
    }
  };

  return (
    <PageWrapper>
      <Loader show={loading.show}>{loading.message}</Loader>
      <Title text={t("user-training-needs-title")} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <UploadContainer>
          <Label
            text={t("user-training-needs-upload-label")}
            style={{ minWidth: "25%" }}
          ></Label>
          <NameWrapperContainer>
            <NameWrapper alt={file?.name}>{file?.name}</NameWrapper>
            {file && (
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  justifyContent: "center",
                  flexShrink: "0",
                }}
                onClick={() => {
                  setFile(null);
                  if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                  }
                }}
              >
                <CircleX width={18} height={18} />
              </button>
            )}
          </NameWrapperContainer>
          <ButtonWrapper>
            <Button
              id="file-upload"
              label={t("user-main-info-select-button")}
              color={Color.GREEN}
              icon={Icons.BUTTONS.SEARCH}
              onClick={handleClickRef}
            />
            <input
              id="file-upload"
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <Button
              id="file-process"
              label={t("user-main-info-upload-button")}
              color={Color.BLUE}
              icon={Icons.BUTTONS.UPLOAD}
              onClick={handleFileProcess}
            />
          </ButtonWrapper>
        </UploadContainer>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginRight: "5rem",
          marginTop: "1rem",
        }}
      >
        <Button
          label="Scarica Template"
          color={Color.GREEN}
          icon={Icons.BUTTONS.DOWNLOAD}
          onClick={() => handleDownloadTemplate()}
        />
      </div>
      <FabbisognoContainer onSubmit={handleBatchSave}>
        <Ariakit.DialogHeading className="heading">
          Fabbisogno formativo calcolato
        </Ariakit.DialogHeading>
        <DialogContent>
          {/* TITOLI */}
          <THead>
            <p>Titolo Corso</p>
            <p>Fabbisogno formativo</p>
            <p>Svolti</p>
            <p>Da fare</p>
            <p></p>
          </THead>
          {/* ROWS TABLE */}
          {fabbisognoData.map((row) => (
            <TRow key={row.id}>
              <p style={{ textAlign: "left" }}>{row.courseTitle}</p>
              <div>
                <InputField>
                  <input
                    type="number"
                    name={`trainingNeed-${row.id}`}
                    defaultValue={row.trainingNeed || 0}
                    style={{
                      width: "40%",
                      padding: "0.2em",
                      textAlign: "end",
                    }}
                  />
                </InputField>
              </div>
              <p>{row.active}</p>
              <p>{row.pending}</p>
              <a
                key={row.id}
                href={(() => {
                  const params = new URLSearchParams();
                  params.append("corso", row.courseTitle);
                  return `/attestati?${params.toString()}`;
                })()}
              >
                <Button
                  style={{ marginTop: 0 }}
                  label="DETTAGLIO"
                  color={Color.BLUE}
                />
              </a>
            </TRow>
          ))}
          <div
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "0.3rem",
                color: Color.BLUE,
                fontStyle: "italic", // Aggiunta per rendere il testo in corsivo
              }}
            >
              <p>
                Validato il{" "}
                {trainingNeedsLog?.validationDate
                  ? format(
                      new Date(trainingNeedsLog.validationDate),
                      "dd/MM/yyyy"
                    )
                  : "N/A"}
              </p>
              <p>da {trainingNeedsLog?.user.firstName}</p>
              <p>{trainingNeedsLog?.user.lastName}</p>
            </div>
            <Button
              style={{ marginTop: 0 }}
              label="Salva e valida"
              type="submit"
            />
          </div>
        </DialogContent>
      </FabbisognoContainer>
    </PageWrapper>
  );
};

export default TrainingNeedsPage;
