import {AxiosError} from "axios";
import {BE_BASE_URL, BE_RESERVATION_ENDPOINT} from "../../utils/config";
import {ReservedCourse} from "../../types";
import {axiosInstance} from "../../provider/authProvider";
import {goToPage} from "../../utils/utils";

export function reserveCourseDate(variationId: number, participants: string[]) {
  try {
    const axiosResponse = axiosInstance.post(BE_BASE_URL + BE_RESERVATION_ENDPOINT + 'date', {
      variationId: variationId,
      participants: participants
    })
    return axiosResponse.then(response => response.status)
  } catch (e: unknown) {
    const err = e as AxiosError;
    if (err.response && err.response.data && err.message) {
      return Promise.reject(err.status)
    } else {
      return Promise.reject(err.status)
    }
  }
}

export function getReserveCourseDateByCompanyId(variationId: number) {
  try {
    const axiosResponse = axiosInstance.get<Set<string>>(BE_BASE_URL + BE_RESERVATION_ENDPOINT + 'getParticipantsByCompanyAndDate', {
      params: {
        variationId: variationId
      }
    })
    return axiosResponse.then(response => response.data)
  } catch (e: unknown) {
    const err = e as AxiosError;
    console.log("generic error", err);
    goToPage('500');
  }
}

export function getParticipantsByCompanyAndDate(variationId: number) {
  try {
    const axiosResponse = axiosInstance.get<Set<string>>(BE_BASE_URL + BE_RESERVATION_ENDPOINT + 'getParticipantsByCompanyAndDate', {
      params: {
        variationId: variationId
      }
    })
    return axiosResponse.then(response => response.data)
  } catch (e: unknown) {
    const err = e as AxiosError;
    console.log("generic error", err);
    goToPage('500');
  }
}

export function getAllReserveCourseDateByCompanyId() {
  try {
    const axiosResponse = axiosInstance.get<ReservedCourse[]>(BE_BASE_URL + BE_RESERVATION_ENDPOINT + 'getAllByCompany')
    return axiosResponse.then(response => response.data)
  } catch (e: unknown) {
    const err = e as AxiosError;
    console.log("generic error", err);
    goToPage('500');
  }
}
