import styled from "styled-components";
import {Color} from "../../../constants/color";
import Label from "../../label";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
  saveError: boolean
}

const FeedbackDialog = styled.div <{
  $error: boolean;
}>`
    position: fixed;
    top: 200px;
    background-color: ${props => props.$error ? Color.PINK : Color.MODAL_BODY_GREEN};
    height: 60px;
    width: 240px;
    padding: 4px;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.$error ? Color.RED : Color.LIGHT_GREEN};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
`;

const Feedback = ({saveError} : Props) => {
  const { t } = useTranslation();
  return (
      <FeedbackDialog $error={saveError}>
        <Label text={saveError ? t("reserve-error") : t("reserve-success")}
               color={saveError ? Color.RED : Color.DARK_GREY}
               fontSize={"20px"}
               textAlign={'center'}
        />
      </FeedbackDialog>
  );
}

export default Feedback
