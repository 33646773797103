import React from "react";
import Button from "../../button/button";
import { BE_BASE_URL } from "../../../utils/config";
import { useCallback, useState } from "react";
import { BaseCourse, CorsoType, Employee } from "../../../types";
import { axiosInstance } from "../../../provider/authProvider";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { Select, SelectItem, SelectProvider } from "../../form/select";
import { Color } from "../../../constants/color";
import { CircleX, XCircle } from "lucide-react";
import styled from "styled-components";
import Label from "../../label";
import * as Ariakit from "@ariakit/react";
import ResetCss from "../../ResetCss";

const selectNominationsStyle = {
  "--padBlock": "0.3rem",
  border: "1px solid var(--blue300)",
  borderRadius: "1rem",
  fontSize: "1rem",
};

const BadgeDiv = styled.div`
  border: 1px solid ${Color.BLUE};
  background-color: ${Color.LIGHT_GREY};
  border-radius: 1rem;
  padding: 4px 8px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

interface SuccessModalProps {
  isVisible: boolean;
  employee: Employee | null;
  onClose: () => void;
}

const ChooseCourseModal: React.FC<SuccessModalProps> = ({
  isVisible,
  employee,
  onClose,
}) => {
  const [courses, setCourses] = useState<CorsoType[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<CorsoType[]>([]);
  const [selectedMissingCourse, setSelectedMissingCourse] =
    useState<string>("");

  const fetchCourses = useCallback(async () => {
    try {
      const response = await axiosInstance.get<
        Omit<BaseCourse, "variationId">[]
      >(`${BE_BASE_URL}/courses-missing`);
      setCourses(response.data);
    } catch (e) {
      const error = e as AxiosError;
      alert(error.message || "Errore durante il recupero dei documenti.");
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      fetchCourses();
    }
  }, [isVisible, fetchCourses]);

  const handleRemoveCourse = async (courseId: number) => {
    setSelectedCourse((prev) => prev.filter((c) => c.id !== courseId));
  };

  const courseStore = Ariakit.useSelectStore({
    value: selectedMissingCourse,
    setValue: (value) => {
      setSelectedMissingCourse(value);
      if (value) {
        const selectedOption = courses.find((c) => c.name === value);
        if (selectedOption) {
          setSelectedCourse((prev) => [
            ...prev,
            {
              id: selectedOption.id,
              name: selectedOption.name,
              code: selectedOption.code,
            },
          ]);
          setSelectedMissingCourse("");
        }
      }
    },
  });

  const renderCourseValue = (value: string | string[]) => {
    if (Array.isArray(value)) {
      const selectedOptions = courses.filter((option) =>
        value.includes(option.name)
      );
      return selectedOptions.length > 0
        ? selectedOptions.map((option) => option.name).join(", ")
        : "Seleziona un corso...";
    } else {
      const selectedOption = courses.find((option) => option.name === value);
      return selectedOption ? selectedOption.name : "Seleziona una corso...";
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    selectedCourse.forEach((course) => {
      formData.append("ids", course.id.toString());
    });
    if (employee?.id) {
      formData.append("employeeId", employee.id.toString());
    } else {
      alert("L'ID dell'impiegato è nullo e non può essere inviato.");
      return;
    }
    try {
      const response = await axiosInstance.patch(
        `${BE_BASE_URL}/courses-missing`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        alert("Corsi assegnati con successo al dipendente!");
        onClose();
        setSelectedCourse([]);
        setSelectedMissingCourse("");
      }
    } catch (e) {
      const error = e as AxiosError;
      alert(error.message || "Errore durante l'assegnazione dei corsi.");
    }
  };

  return (
    <ResetCss>
      <Ariakit.Dialog
        open={isVisible}
        onClose={() => {
          setSelectedCourse([]);
          setSelectedMissingCourse("");
          onClose();
        }}
        className="dialog"
        style={{
          overflowX: "hidden",
        }}
      >
        <Ariakit.DialogHeading className="heading">
          {employee?.firstName} {employee?.lastName}
          <Ariakit.DialogDismiss
            style={{
              position: "absolute",
              backgroundColor: "transparent",
              border: "none",
              top: "calc(50% - 12px)",
              right: ".5em",
              cursor: "pointer",
            }}
          >
            <XCircle size={24} color={Color.GREEN} />
          </Ariakit.DialogDismiss>
        </Ariakit.DialogHeading>
        <div
          style={{
            marginTop: "0",
            padding: "0 2rem",
            overflowX: "auto",
          }}
        >
          <p
            style={{
              fontSize: "1.2rem",
              color: Color.BLUE,
            }}
          >
            È possibile assegnare i corsi necessari a {employee?.firstName}.
          </p>
          <p
            style={{
              color: Color.BLUE,
            }}
          >
            Per procedere, selezionare i corsi di interesse dal menù a tendina.
            Se non fosse necessario o si volesse fare successivamente, chiudere
            la finestra.
          </p>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <SelectProvider store={courseStore}>
                <Select
                  style={selectNominationsStyle}
                  name="course"
                  renderValue={renderCourseValue}
                  value={selectedMissingCourse}
                  onChange={(e) => {
                    const target = e.target as HTMLSelectElement;
                    const value = target.value;
                    courseStore.setValue(value);
                  }}
                  modal
                >
                  {courses.map((course) => (
                    <SelectItem
                      key={course.id}
                      value={course.name}
                      disabled={selectedCourse.some(
                        (c) => c.name === course.name
                      )}
                    >
                      {course.name}
                    </SelectItem>
                  ))}
                </Select>
              </SelectProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.2rem",
                marginTop: "1.5rem",
              }}
            >
              {selectedCourse.map((course) => (
                <BadgeDiv key={course.id}>
                  <Label
                    color={Color.DARK_BLUE}
                    text={course.name}
                    fontSize={"14px"}
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveCourse(course.id)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "red",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <CircleX width={16} height={16} />
                  </button>
                </BadgeDiv>
              ))}
            </div>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "end",
                padding: "1rem 0",
              }}
            >
              {selectedCourse.length > 0 && (
                <Button
                  type="submit"
                  label="Assegna ai Corsi"
                  color={Color.GREEN}
                />
              )}
            </span>
          </form>
        </div>
      </Ariakit.Dialog>
    </ResetCss>
  );
};

export default ChooseCourseModal;
