import Antincendio from "../resources/course-image/antincendio.jpg";
import Haccp from "../resources/course-image/haccp.jpg";
import Preposto from "../resources/course-image/preposto.jpg";
import PrimoSoccorso from "../resources/course-image/primo-soccorso.jpg";
import Rspp from "../resources/course-image/rspp.jpg";
import Spazi from "../resources/course-image/spazi.jpg";

/*
1,Antincendio
2,Primo Soccorso
3,Sicurezza Sul Lavoro
4,Haccp
5,Attrezzature
6,Rischi Specifici
*/

export const Cover = new Map<number, { name: string; icon: string }>([
  [1, { name: "ANTINCENDIO", icon: Antincendio }],
  [2, { name: "PRIMO SOCCORSO", icon: PrimoSoccorso }],
  [3, { name: "SICUREZZA", icon: Rspp }],
  [4, { name: "HACCP", icon: Haccp }],
  [5, { name: "PREPOSTO", icon: Preposto }],
  [6, { name: "SPAZI", icon: Spazi }],
]);
