import * as React from "react";
import * as Ariakit from "@ariakit/react";
import styled from "styled-components";

import { Color } from "../../../constants/color";
import { ChevronDown } from "lucide-react";

/*
Proxy components for Ariakit Select components
Ariakit docs: https://ariakit.org
Select usage: https://ariakit.org/components/select
Select API: https://ariakit.org/reference/select-provider
*/

const SelectProvider = Ariakit.SelectProvider;

const StyledSelectLabel = styled(Ariakit.SelectLabel)`
  margin-left: 0.5rem;
  font-family: Avenir Next, sans-serif;
  font-weight: bold;
  color: ${Color.LIGHT_BLUE};
`;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof StyledSelectLabel>,
  React.ComponentPropsWithoutRef<typeof StyledSelectLabel>
>(({ children, ...props }, ref) => {
  return (
    <StyledSelectLabel {...props} ref={ref}>
      {children}
    </StyledSelectLabel>
  );
});
SelectLabel.displayName = StyledSelectLabel.displayName;

const StyledSelect = styled(Ariakit.Select)`
  --defaultPadBlock: 0.2rem;
  --padBlock: var(--defaultPadBlock);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${Color.LIGHT_BLUE};
  border-radius: 1rem;
  padding: var(--padBlock) var(--padBlock) var(--padBlock) 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 100%;
  min-height: 2rem;
  background-color: ${Color.WHITE};
  user-select: none;
  white-space: nowrap;
  text-decoration-line: none;
  outline-width: 1px;
  outline-offset: 1px;
  outline-color: ${Color.LIGHT_BLUE};
  overflow: hidden;
  text-overflow: ellipsis;

  &:after {
    --blockSize: calc(2rem + var(--padBlock) - var(--defaultPadBlock));
    content: "";
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    left: calc(100% - var(--blockSize));
    width: var(--blockSize);
    background-color: ${Color.LIGHT_BLUE};
  }

  & svg {
    position: absolute;
    top: calc(4px + var(--padBlock) - var(--defaultPadBlock));
    right: 5px;
    bottom: 0;
    z-index: 1;
  }

  &[aria-disabled="true"] {
    opacity: 0.5;
  }

  &:focus-visible,
  &[data-focus-visible] {
    outline: 1px solid ${Color.LIGHT_BLUE};
    outline-offset: -1px;
  }
`;

const StyledSelectValue = styled(Ariakit.SelectValue)``;

// noinspection CssUnresolvedCustomProperty
const StyledSelectPopover = styled(Ariakit.SelectPopover)`
  z-index: 50;
  display: flex;
  max-height: min(var(--popover-available-height, 300px), 300px);
  flex-direction: column;
  overscroll-behavior: contain;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${Color.GREY};
  background-color: white;
  padding: 0.5rem;
  color: ${Color.LIGHT_BLUE};
  box-shadow: 0 10px 15px -3px var(--shadow), 0 4px 6px -4px var(--shadow);
  overflow: auto;
`;

type SelectValueProps = React.ComponentPropsWithoutRef<
  typeof StyledSelectValue
>;
type SelectProps = React.ComponentPropsWithoutRef<typeof StyledSelect> & {
  renderValue?: SelectValueProps["children"];
  sameWidth?: boolean;
  modal?: boolean;
};

const Select = React.forwardRef<
  React.ElementRef<typeof StyledSelect>,
  SelectProps
>(
  (
    { renderValue, sameWidth = true, modal = false, children, ...props },
    ref
  ) => {
    return (
      <>
        <StyledSelect {...props} ref={ref}>
          <span>
            {renderValue ? (
              <StyledSelectValue>{renderValue}</StyledSelectValue>
            ) : (
              <StyledSelectValue />
            )}
          </span>
          <ChevronDown color={Color.WHITE} width={24} height={24} />
        </StyledSelect>
        <StyledSelectPopover gutter={4} sameWidth={sameWidth} modal={modal}>
          {children}
        </StyledSelectPopover>
      </>
    );
  }
);
Select.displayName = StyledSelect.displayName;

const StyledSelectItem = styled(Ariakit.SelectItem)`
  display: flex;
  cursor: default;
  scroll-margin: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6px;
  padding: 0.5rem;
  outline: none !important;

  &[aria-disabled="true"] {
    opacity: 0.5;
  }

  &[data-active-item] {
    background-color: ${Color.LIGHTER_BLUE};
    color: ${Color.DARK_BLUE};
    font-weight: bold;
  }
`;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof StyledSelectItem>,
  React.ComponentPropsWithoutRef<typeof StyledSelectItem>
>((props, ref) => {
  return <StyledSelectItem {...props} ref={ref} />;
});
SelectItem.displayName = StyledSelectItem.displayName;

export { SelectProvider, SelectLabel, Select, SelectItem };
