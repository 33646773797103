import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import styled from "styled-components";

import {
  Select,
  SelectItem,
  SelectLabel,
  SelectProvider,
} from "../form/select";

import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";
import { axiosInstance, useAuth } from "../../provider/authProvider";
import * as Ariakit from "@ariakit/react";
import {
  Bell,
  CalendarDays,
  Check,
  CheckCircle,
  ChevronDown,
  FileDown,
  Search,
  X,
} from "lucide-react";
import {
  dateOptionsNumeric,
  getCertificateExpireState,
  getCourseIcon,
  getLocale,
  goToPage,
} from "../../utils/utils";
import { BE_BASE_URL, ATTESTATO_DOC_TYPE_ID } from "../../utils/config";
import { format } from "date-fns";
import { DocumentData, Employee, InfoDocumentale } from "../../types";
import { useAppDispatch } from "../../app/hooks";
import { storeEmployees } from "../../features/employee/employeeSlice";
import { BarLoader } from "react-spinners";
import {
  BookableEmployees,
  BookableEmployeeType,
  SelectedBookableEmployeeType,
} from "./BookableEmployees";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 4rem;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 2rem;
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    gap: 1rem;
  }
`;

const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  & label {
    margin-left: 0.5rem;
    font-family: Avenir Next, sans-serif;
    font-weight: bold;
    color: ${Color.LIGHT_BLUE};
  }
  & input {
    border: 1px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    padding: 0.2rem 1rem;
    width: 100%;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.LIGHT_BLUE};
  color: ${Color.WHITE};
  top: 0;
  right: 0;
  bottom: 0;
  width: 2.5rem;
  border-radius: 0 1rem 1rem 0;
`;

type FiltersType = {
  corso: string;
  anno: string;
  sede: string;
  stato: number;
};

const statoAttestati: { [key: number]: string } = {
  0: "TUTTI",
  1: "VALIDO",
  2: "IN SCADENZA",
  3: "SCADUTO",
  4: "MANCANTI",
};

export default function RicercaPerCorso() {
  const { token } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const filterStatus = urlParams.get("status") || "0";
  const filterTitoloCorso = urlParams.get("corso") || "";

  const [loading, setLoading] = useState(false);
  const [corsi, setCorsi] = useState<Map<string, string>>(new Map());
  const [attestati, setAttestati] = useState<Map<string, DocumentData[]>>(
    new Map()
  );

  const [filters, setFilters] = useState<FiltersType>({
    corso: filterTitoloCorso,
    anno: "",
    sede: "",
    stato: +filterStatus,
  });

  const debouncedFilters = useDebounce(filters, 300);

  const selectStato = Ariakit.useSelectStore({
    value: `${filters.stato}`,
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, stato: +value }));
    },
  });

  useEffect(() => {
    async function fetchAttestati(filters: FiltersType) {
      const url = `${BE_BASE_URL}/certificates`;
      const params = new URLSearchParams();
      params.append("page", "1");
      params.append("size", "1000");
      params.append("order", "titolo_corso");
      params.append("dTId", ATTESTATO_DOC_TYPE_ID.toString());
      const paramFilter = [];
      if (filters.corso) {
        // titolo corso like
        const op = filters.corso === filterTitoloCorso ? "eq" : "like";
        paramFilter.push(`titolo_corso:${op}:"${filters.corso}"`);
      }
      if (filters.anno && filters.anno.length === 4 && !isNaN(+filters.anno)) {
        // build two dates for the year
        const startDate = new Date(+filters.anno, 0, 1);
        const endDate = new Date(+filters.anno, 11, 31);
        // inizio_corso between
        paramFilter.push(
          `inizio_corso:btw:${format(startDate, "yyyyMMdd")},${format(
            endDate,
            "yyyyMMdd"
          )}`
        );
      }
      if (filters.sede) {
        // sede_azienda like
        paramFilter.push(`sede_azienda:like:"${filters.sede}"`);
      }
      if (paramFilter.length > 0) {
        params.append("filter", paramFilter.join(" AND "));
      }
      const result = await axiosInstance.get<InfoDocumentale>(
        `${url}?${params.toString()}`
      );
      const titoloCorsiMap: Map<string, string> = new Map();
      const corsiMap: Map<string, DocumentData[]> = new Map();
      if (result.data && result.data.ok) {
        const corsi: DocumentData[] = result.data.content.data;
        corsi.forEach((c: DocumentData) => {
          const metadata = c.metadata;
          const statusExpire = getCertificateExpireState(c.metadata);

          // Filtro stato attestato lo possiamo fare solo in pagina per ora
          // il documentale non supporta filtri su campi calcolati
          // SE ho il filtro definito e lo stato è differente non và visualizzato
          if (
            debouncedFilters.stato &&
            statusExpire !== debouncedFilters.stato
          ) {
            return;
          }

          if (corsiMap.has(metadata.codice_corso)) {
            let cDataArray = corsiMap.get(metadata.codice_corso);
            if (cDataArray) {
              cDataArray.push(c);
            } else {
              cDataArray = [c];
            }
            corsiMap.set(metadata.codice_corso, cDataArray);
          } else {
            titoloCorsiMap.set(metadata.codice_corso, metadata.titolo_corso);
            corsiMap.set(metadata.codice_corso, [c]);
          }
        });
      }
      return { corsiMap, titoloCorsiMap };
    }

    function applyFilterCorsi(
      corsiMap: Map<string, DocumentData[]>,
      titoloCorsiMap: Map<string, string>
    ): Map<string, DocumentData[]> {
      if (debouncedFilters.corso) {
        const searchEqual = debouncedFilters.corso === filterTitoloCorso;
        // Ciclo la mappa corsiMap, per ogni corso verifico se il titolo contiene il filtro
        // se non lo contiene lo elimino dalla mappa
        corsiMap.forEach((value, key) => {
          const newValue = value.filter((v) => {
            if (searchEqual) {
              if (
                v.metadata.titolo_corso.toLowerCase() ===
                debouncedFilters.corso.toLowerCase()
              ) {
                return v;
              }
            } else {
              if (
                v.metadata.titolo_corso
                  .toLowerCase()
                  .includes(debouncedFilters.corso.toLowerCase())
              ) {
                return v;
              }
            }
          });
          if (newValue.length === 0) {
            corsiMap.delete(key);
            titoloCorsiMap.delete(key);
          } else {
            corsiMap.set(key, newValue);
          }
        });
      }
      if (debouncedFilters.sede) {
        // Ciclo la mappa corsiMap, per ogni corso verifico se la sede è uguale al filtro
        // se non lo è lo elimino dalla mappa
        corsiMap.forEach((value, key) => {
          const newValue = value.filter((v) => {
            if (
              v.metadata.sede_azienda
                .toLowerCase()
                .includes(debouncedFilters.sede.toLowerCase())
            ) {
              return v;
            }
          });
          if (newValue.length === 0) {
            corsiMap.delete(key);
            titoloCorsiMap.delete(key);
          } else {
            corsiMap.set(key, newValue);
          }
        });
      }
      return corsiMap;
    }

    function applyFilterTitoli(
      titoliMap: Map<string, string>
    ): Map<string, string> {
      // Verifico presenza filtro sul titolo corso
      if (debouncedFilters.corso) {
        titoliMap.forEach((value, key) => {
          if (
            !value.toLowerCase().includes(debouncedFilters.corso.toLowerCase())
          ) {
            titoliMap.delete(key);
          }
        });
      }
      return titoliMap;
    }

    setLoading(true);
    fetchAttestati(debouncedFilters)
      .then(({ corsiMap, titoloCorsiMap }) => {
        // eseguo un ulteriore filtro perchè i corsi MANCANTI non vengono filtrati dal BE
        setAttestati(applyFilterCorsi(corsiMap, titoloCorsiMap));
        titoloCorsiMap = applyFilterTitoli(titoloCorsiMap);
        // Ordiniamo la mappa<key, value> dei corsi in base al value
        const sortedCorsi = new Map(
          Array.from(titoloCorsiMap).sort((a, b) => a[1].localeCompare(b[1]))
        );
        setCorsi(sortedCorsi);
        // console.log("ordinamento", { titoloCorsiMap, sortedCorsi });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debouncedFilters, token]);

  return (
    <Layout>
      <SearchContainer>
        <SearchInput>
          <label htmlFor="searchCorso">CORSO</label>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchCorso"
              type="text"
              placeholder="Ricerca per corso"
              value={filters.corso}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, corso: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SearchInput>
          <label htmlFor="searchAnno">ANNO</label>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchAnno"
              type="text"
              placeholder="Ricerca per anno"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, anno: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SearchInput>
          <label htmlFor="searchSede">SEDE AZIENDALE</label>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchSede"
              type="text"
              placeholder="Ricerca per sede"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, sede: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <div>
          <SelectProvider store={selectStato}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.1rem",
              }}
            >
              <SelectLabel
                style={{
                  paddingLeft: "0.5rem",
                }}
              >
                STATO
              </SelectLabel>
              <Select
                style={{
                  minWidth: "200px",
                }}
                renderValue={(value) => statoAttestati[+value]}
              >
                {Object.entries(statoAttestati).map(([key, value]) => (
                  <SelectItem key={`statoAttestato_${key}`} value={key}>
                    {value}
                  </SelectItem>
                ))}
              </Select>
            </div>
          </SelectProvider>
        </div>
      </SearchContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          width: "78rem",
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "column",
              fontSize: "1.5rem",
              marginTop: "4rem",
            }}
          >
            <p>Caricamento corsi...</p>
            <BarLoader width="20ch" loading={loading} />
          </div>
        ) : (
          corsi &&
          corsi.size === 0 && (
            <p
              style={{
                fontSize: "1.5rem",
                marginTop: "4rem",
              }}
            >
              Nessun corso trovato
            </p>
          )
        )}
        {corsi &&
          corsi.size > 0 &&
          Array.from(corsi.entries()).map(([key, value]) => {
            const titleCorso = value;
            const aList = attestati.get(key);
            return (
              <div
                key={key}
                style={{
                  width: "100%",
                  filter: "drop-shadow(4px -4px 5px var(--shadow800))",
                }}
              >
                <CourseAccordion
                  icon={getCourseIcon(
                    aList ? aList[0].metadata.tipo_corso : -1
                  )}
                  title={titleCorso}
                  code={key}
                  attestati={aList || []}
                  isOpen={filterTitoloCorso === titleCorso ? true : undefined}
                />
              </div>
            );
          })}
      </div>
    </Layout>
  );
}

const Disclosure = styled(Ariakit.Disclosure)`
  border-radius: 0.8rem;
  background-color: ${Color.WHITE};
  width: 100%;
  position: relative;
  overflow: hidden;
  border: none;
  padding: 0.5rem 1.5rem;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    width: 1rem;
    background-color: ${Color.LIGHT_BLUE};
  }

  & p[role='button'] {
    position: absolute;
    right: 0;
    top: 12px;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
    border-radius: 0.8rem 0 0 0.8rem;
    background-color: ${Color.GREEN};
    color: ${Color.WHITE};
    font-weight: bold;
`;

const DisclosureContent = styled(Ariakit.DisclosureContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: calc(100% - 0.8rem);
  margin: -8px auto 0 auto;

  background-color: ${Color.CERAMIC};
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  padding: 1.6rem 2rem;
  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    border-radius: 4rem;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: ${Color.GREY};
  }
`;

const TableAttestati = styled.div`
  display: grid;
  grid-template-columns: auto 150px 200px 150px 150px 100px 60px;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

const TableAttestatiHeader = styled(TableAttestati)`
  text-transform: uppercase;
  color: ${Color.LIGHT_BLUE};
`;

const TableAttestatiRow = styled(TableAttestati)`
  border: 1px solid ${Color.GREY};
  border-radius: 16px;
  text-transform: uppercase;
  color: ${Color.DARK_GREY};
  background-color: ${Color.LIGHT_GREY};
  min-height: 3.2rem;
`;

const OutlineButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${Color.WHITE};
  background-color: ${Color.BLUE};
  border: 0;
  padding: 0.3em 1.5em;
  border-radius: 8px;
  font-size: 1.6em;
  font-weight: bold;
  cursor: pointer;
`;

function CourseAccordion({
  icon,
  title,
  code,
  attestati,
  isOpen,
}: {
  icon?: React.ReactNode;
  title: string;
  code: string;
  attestati: DocumentData[];
  isOpen?: boolean;
}) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [filteredAttestati, setFilteredAttestati] =
    useState<DocumentData[]>(attestati);
  const [bookableEmployees, setBookableEmployees] = useState<
    BookableEmployeeType[]
  >([]);
  const [selectedEmployee, setSelectedEmployee] = useState<
    SelectedBookableEmployeeType[]
  >([]);
  const [partecipantiCorso, setPartecipantiCorso] = useState<string[]>([]);
  const [searchPartecipante, setSearchPartecipante] = useState("");

  const debauncedSearchPartecipante = useDebounce(searchPartecipante, 300);

  function handlePartecipanteCorso(cf: string) {
    if (partecipantiCorso.includes(cf)) {
      setPartecipantiCorso((prev) => prev.filter((p) => p !== cf));
    } else {
      setPartecipantiCorso((prev) => [...prev, cf]);
    }
  }

  useEffect(() => {
    async function fetchBookableEmployees(courseCode: string) {
      const url = `${BE_BASE_URL}/employee/bookable/${courseCode}`;
      const response = await axiosInstance.get<Employee[]>(url);
      // console.log("fetchBookableEmployees for " + courseCode, { response });
      if (response.status === 200 && response.data) {
        const employees: BookableEmployeeType[] = response.data.map((d) => ({
          cf: d.cf,
          name: `${d.lastName} ${d.firstName}`.toUpperCase(),
        }));
        setBookableEmployees(
          employees.sort((a, b) => a.name.localeCompare(b.name))
        );
      } else {
        setBookableEmployees([]);
      }
    }
    setLoading(true);
    fetchBookableEmployees(code).finally(() => setLoading(false));
  }, [code]);

  useEffect(() => {
    let filtered = [...attestati];
    if (debauncedSearchPartecipante) {
      filtered = filtered.filter((a) =>
        a.metadata.name
          .toLowerCase()
          .includes(debauncedSearchPartecipante.toLowerCase())
      );
    }
    // ordino i partecipanti per scadenza
    //TODO fix with date exp
    filtered.sort((a, b) => {
      if (!a.metadata.data_scadenza) {
        return -1;
      }
      if (!b.metadata.data_scadenza) {
        return 1;
      }
      return (
        new Date(a.metadata.data_scadenza).getTime() -
        new Date(b.metadata.data_scadenza).getTime()
      );
    });
    setFilteredAttestati(filtered);
  }, [debauncedSearchPartecipante, attestati]);

  function renderScadenza(attestato: DocumentData) {
    const status_expire: number = getCertificateExpireState(attestato.metadata);
    const missingExpire: boolean =
      new Date(attestato.metadata.data_scadenza).toLocaleDateString(
        getLocale(),
        dateOptionsNumeric
      ) === "Invalid Date";
    const color =
      status_expire === 1
        ? Color.GREEN
        : status_expire === 2
        ? Color.YELLOW
        : status_expire === 3
        ? Color.RED
        : status_expire === 4
        ? Color.RED
        : "transparent";
    const label =
      status_expire === 1
        ? missingExpire
          ? "VALIDO "
          : "VALIDO FINO AL "
        : status_expire === 2
        ? "SCADE IL "
        : status_expire === 3
        ? "SCADUTO IL "
        : status_expire === 4
        ? "MANCANTE"
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
        }}
      >
        <Bell color={color} fill={color} width={24} height={24} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".2rem",
            color,
          }}
        >
          <p>{label}</p>
          {status_expire !== 4 && !missingExpire && (
            <p>
              {new Date(attestato.metadata.data_scadenza).toLocaleDateString(
                getLocale(),
                dateOptionsNumeric
              )}
            </p>
          )}
        </div>
      </div>
    );
  }

  function renderInfo(attestato: DocumentData) {
    const status: number =
      attestato.metadata.data_prenotazione !== undefined ? 2 : 1;
    const label =
      status === 1
        ? ""
        : status === 2
        ? "PRENOTATO IL " +
          new Date(attestato.metadata.data_prenotazione).toLocaleDateString(
            getLocale(),
            dateOptionsNumeric
          )
        : "";
    // console.log("renderInfo attestato", { attestato, status, label });
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
          color: Color.BLUE,
        }}
      >
        {status === 2 ? (
          <CalendarDays color={Color.BLUE} width={24} height={24} />
        ) : null}
        <p>{label}</p>
      </div>
    );
  }

  async function handleDownloadAttestato(id: number, fileName: string) {
    const url = `${BE_BASE_URL}/certificates/${id}`;
    const response = await axiosInstance({
      url,
      method: "GET",
      responseType: "blob",
    });
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);
    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const handleEmployeeBooked = async (
    cf: string,
    courseMissingId: number,
    index: number
  ) => {
    const selected = bookableEmployees.find((e) => e.cf === cf);
    if (!selected) {
      console.error(
        "Errore imprevisto: il dipendente selezionato non è stato trovato fra i prenotabili",
        { cf, bookableEmployees }
      );
      return;
    }
    // assegno il dipende al corso mancante
    await axiosInstance.put(
      `${BE_BASE_URL}/courses/missings/${courseMissingId}`,
      {
        cf,
      }
    );
    // aggiorno la lista dei partecipanti assegnati al corso
    setSelectedEmployee((prev) => [
      ...prev,
      { cf: selected.cf, name: selected.name, index },
    ]);
    // rimuovo il dipendente dalla lista dei dipendenti prenotabili
    setBookableEmployees((prev) => prev.filter((e) => e.cf !== selected.cf));
  };

  const handleUndoEmployeeBooked = async (
    selected: SelectedBookableEmployeeType,
    courseMissingId: number
  ) => {
    // rimuovo il dipendente dal corso mancante
    await axiosInstance.put(
      `${BE_BASE_URL}/courses/missings/${courseMissingId}`,
      {
        cf: null,
      }
    );
    if (selected) {
      // rimuovo il dipendente dalla lista dei partecipanti selezionati
      setSelectedEmployee((prev) =>
        prev.filter((se) => se.index !== selected.index)
      );
      // aggiungo il dipendente alla lista dei dipendenti prenotabili, ordinando la lista
      setBookableEmployees((prev) => {
        const newArray = [...prev, { cf: selected.cf, name: selected.name }];
        return newArray.sort((a, b) => a.name.localeCompare(b.name));
      });
      //console.log("handleUndoEmployeeBooked", { selected, courseMissingId });
      // Fra gli attestati devo rimuovere l'assegnazione al dipendente
      setFilteredAttestati((prev) =>
        prev.map((a, index) => {
          if (index === selected.index) {
            // Verifico sia un mancante assegnato
            const isMancanteAssegnato = !!(
              a.metadata.mancante &&
              a.metadata.cf_discente &&
              !a.metadata.data_prenotazione
            );
            if (isMancanteAssegnato) {
              // Resetto le info del discente dall'attestato
              a.metadata.cf_discente = "";
              a.metadata.nome_discente = "";
              a.metadata.cognome_discente = "";
            }
          }
          return a;
        })
      );
    }
  };

  return (
    <Ariakit.DisclosureProvider open={isOpen}>
      <Disclosure>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: Color.LIGHT_BLUE,
              fontFamily: "Avenir Next Bold, sans-serif",
            }}
          >
            {icon}
            <p>{title}</p>
          </div>
          <p role="button">
            <ChevronDown color={Color.WHITE} size={32} />
          </p>
        </div>
      </Disclosure>
      <DisclosureContent>
        <SearchInput style={{ width: "90%" }}>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchPartecipantiCorso"
              type="text"
              placeholder="Ricerca i partecipanti"
              onChange={(e) => setSearchPartecipante(e.target.value)}
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: ".5rem",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                flexDirection: "column",
                fontSize: "1.5rem",
              }}
            >
              <p>Caricamento dettagli...</p>
              <BarLoader width="23ch" loading={loading} />
            </div>
          ) : (
            <>
              <TableAttestatiHeader>
                <p>Nome e cognome</p>
                <p>Sede aziendale</p>
                <p>Ore di formazione</p>
                <p>Stato</p>
                <p>Info</p>
                <p
                  style={{
                    justifySelf: "center",
                  }}
                >
                  Attestato
                </p>
              </TableAttestatiHeader>
              {filteredAttestati.map((a, index) => {
                const mancante = a.metadata.mancante;
                const mancanteAssegnato = !!(
                  mancante &&
                  a.metadata.cf_discente &&
                  !a.metadata.data_prenotazione
                );
                const prenotato = !!(
                  mancante &&
                  a.metadata.cf_discente &&
                  a.metadata.data_prenotazione
                );
                let selected = undefined;
                if (mancante) {
                  if (mancanteAssegnato || prenotato) {
                    selected = {
                      cf: a.metadata.cf_discente,
                      name: `${a.metadata.cognome_discente} ${a.metadata.nome_discente}`.toUpperCase(),
                      index,
                    };
                  } else {
                    selected = selectedEmployee.find(
                      (se) => se.index === index
                    );
                  }
                }
                const employeeCF = selected
                  ? selected.cf
                  : a.metadata.cf_discente;
                const showBookable =
                  (mancante || mancanteAssegnato) && !prenotato;
                return (
                  <TableAttestatiRow key={`attestato_${a.id}`}>
                    {showBookable ? (
                      <BookableMemoized
                        employees={bookableEmployees}
                        selectedEmployee={selected}
                        onEmployeeBooked={(cf: string) =>
                          handleEmployeeBooked(cf, a.id, index)
                        }
                        undoEmployeeBooked={(s) =>
                          handleUndoEmployeeBooked(s, a.id)
                        }
                      />
                    ) : (
                      <p>
                        {a.metadata.cognome_discente +
                          " " +
                          a.metadata.nome_discente}
                      </p>
                    )}
                    <p>{a.metadata.sede_azienda}</p>
                    <p>{a.metadata.durata} ore</p>
                    <div>{renderScadenza(a)}</div>
                    <div>{renderInfo(a)}</div>
                    {a.fileName ? (
                      <FileDown
                        color={Color.LIGHT_BLUE}
                        width={24}
                        height={24}
                        style={{
                          justifySelf: "center",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleDownloadAttestato(a.id, a.fileName)
                        }
                      />
                    ) : (
                      <p></p>
                    )}
                    <div
                      style={{
                        justifySelf: "center",
                      }}
                    >
                      {employeeCF && !prenotato && (
                        <input
                          type="checkbox"
                          onClick={() =>
                            handlePartecipanteCorso(employeeCF.toLowerCase())
                          }
                        />
                      )}
                    </div>
                  </TableAttestatiRow>
                );
              })}
              {filteredAttestati?.length > 0 && (
                <OutlineButton
                  style={{
                    marginLeft: "auto",
                    // marginTop: "-1rem",
                  }}
                  onClick={() => {
                    if (partecipantiCorso && partecipantiCorso.length > 0) {
                      dispatch(storeEmployees(partecipantiCorso));
                      goToPage("corso", `${code.toLowerCase()}/-1`);
                    } else {
                      alert(
                        "Selezionare almeno un partecipante per effettuare una prenotazione"
                      );
                    }
                  }}
                >
                  <CalendarDays color={Color.WHITE} />
                  PRENOTA
                </OutlineButton>
              )}
            </>
          )}
        </div>
      </DisclosureContent>
    </Ariakit.DisclosureProvider>
  );
}

const BookableMemoized = memo(BookableEmployees);
